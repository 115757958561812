require('intersection-observer');
import window from 'global';
import React from 'react';
//import '../../styles/main.scss';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import ReactPlayer from 'react-player';
import Header from './Header/Header.js';
import useSliderSwipe from '../custom_hooks/usesliderswipe.js';
import useHeroParallax from '../custom_hooks/useHeroParallax.js';
import HeaderSlider from './HeaderSlider/HeaderSlider.js';
import TagTitle from './TagTitle/TagTitle.js';
import Wysiwyg from './Wysiwyg/index.js';
import WysiwygNoticia from './WysiwygNoticia/index.js';
import WysiwygEmpleo from './WysiwygEmpleo/index.js';
import ShareSocial from './ShareSocial/index.js';
import BuscadorFiltros from './BuscadorFiltros/index.js';
import ContactForm from './ContactForm/index.js';
import mapaWeb from './mapaWeb/index.js';
import Paginator from './Paginator/index.js';
import Soluciones from './Soluciones/index.js';
import SolucionesMosaico from './SolucionesMosaico/index.js';
import SubscriptionForm from './SubscriptionForm/index.js';
import RSSfeed from './RSSFeed/index.js';
import Cifras from './Cifras/index.js';
import CifrasSlider from './CifrasSlider/index.js';
import DistribuidorIconos from './DistribuidorIconos/index.js';
import Acordeon from './Acordeon/index.js';
import AreasActividad from './AreasActividad/index.js';
import TrabajaConNosotros from './TrabajaConNosotros/index.js';
import TrabajaConNosotrosSimple from './TrabajaConNosotrosSimple/index.js';
import TimeLine from './Timeline/index.js';
import ProjectsMap from './ProjectsMap/ProjectsMap.js';
import Banners from './Banners/index.js';
import Breadcrumbs from './Breadcrumbs/index.js';
import BreadcrumbsOwnDomainLanding from './BreadcrumbsOwnDomainLanding/index.js';
import ContactInfo from './ContactInfo/index.js';
import Descargas from './Descargas/index.js';
import DevelopmentGoals from './DevelopmentGoals/index.js';
import Distribuidor2Col from './Distribuidor2Col/index.js';
import HeroImage from './HeroImage/index.js';
import TestimoniosSlider from './TestimoniosSlider/index.js';
import Table from './Table/Table.js';
import TableDouble from './TableDouble/TableDouble.js';
import Proyectos from './Proyectos/index.js';
import Mediateca from './Mediateca/index.js';
import SolucionesBotonera from './SolucionesBotonera/index.js';
import DistribuidorDestacadoSlider from './DistribuidorDestacadoSlider/index.js';
import Footer from '../layout/Footer.js';
import HeaderLanding from '../layout/HeaderLanding.js';
import HeaderLandingOwnDomain from '../layout/HeaderLandingOwnDomain.js';
import FooterLanding from '../layout/FooterLanding.js';
import FooterLandingOwnDomain from '../layout/FooterLandingOwnDomain.js';
import DistribuidorDestacado from './DistribuidorDestacado/index.js';
import DistribuidorDestacadoAreas from './DistribuidorDestacadoAreas/index.js';
import DistribuidorMultiple from './DistribuidorMultiple/index.js';
import LogosSlider from './LogosSlider/index.js';
import DistributorSimple from './DistribuidorSimple/index.js';
import ModuleNews from './ModuleNews/index.js';
import ModuleLastNews from './ModuleLastNews/index.js';
import BloqueTexto from './Texto/index.js';
import BloqueTextoH1 from './TextoH1/index.js';
import SolucionesFixed from './SolucionesFixed/index.js';
import Resultados from './Resultados/index.js';
import RelatedItems from './RelatedItems/index.js';
import error404 from './error404/index.js';
import websAcciona from './websAcciona/index.js';
import withSolucionesNav from './_wrapper_components/withSolucionesNav.js';
import NewsList from './NewsList/index.js';
import ProjectsList from './ProjectsList/index.js';
import EquipoDirectivo from './EquipoDirectivo/index.js';
import EquipoDirectivoDestacado from './EquipoDirectivoDestacado/index.js';
import DownloadsList from './DownloadsList/index.js';
import TextWithCta from './TextWithCta/index.js';
import WorkDay from './WorkDay/index.js';
import WorkDayDetalleOferta from './WorkDay_DetalleOferta/index.js';
import OwnTechList from './OwnTechList/index.js';
import ProyectosDestacados from './ProyectosDestacados/index.js';
import ExternalArticlesSlider from './ExternalArticlesSlider/index.js';
import BingResults from './BingResults/index.js';
import FormularioSimple from './FormularioSimple/index.js';
import SolucionesStoryFinal from './SolucionesStoryFinal/index.js';

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;
global.ReactPlayer = ReactPlayer;
global.useHeroParallax = useHeroParallax;
global.useSliderSwipe = useSliderSwipe;
global.HeaderSlider = HeaderSlider;
global.TagTitle = TagTitle;
global.Wysiwyg = Wysiwyg;
global.ShareSocial = ShareSocial;
global.BuscadorFiltros = BuscadorFiltros;
global.ContactForm = ContactForm;
global.mapaWeb = mapaWeb;
global.Paginator = Paginator;
global.Soluciones = Soluciones;
global.SolucionesMosaico = SolucionesMosaico;
global.SubscriptionForm = SubscriptionForm;
global.RSSfeed = RSSfeed;
global.Cifras = Cifras;
global.CifrasSlider = CifrasSlider;
global.DistribuidorIconos = DistribuidorIconos;
global.Header = Header;
global.Acordeon = Acordeon;
global.AreasActividad = AreasActividad;
global.TrabajaConNosotros = TrabajaConNosotros;
global.TrabajaConNosotrosSimple = TrabajaConNosotrosSimple;
global.TimeLine = TimeLine;
global.ProjectsMap = ProjectsMap;
global.Banners = Banners;
global.Breadcrumbs = Breadcrumbs;
global.BreadcrumbsOwnDomainLanding = BreadcrumbsOwnDomainLanding;
global.ContactInfo = ContactInfo;
global.Descargas = Descargas;
global.DevelopmentGoals = DevelopmentGoals;
global.Distribuidor2Col = Distribuidor2Col;
global.HeroImage = HeroImage;
global.TestimoniosSlider = TestimoniosSlider;
global.Table = Table;
global.TableDouble = TableDouble;
global.Proyectos = Proyectos;
global.Mediateca = Mediateca;
global.SolucionesBotonera = SolucionesBotonera;
global.DistribuidorDestacadoSlider = DistribuidorDestacadoSlider;
global.Footer = Footer;
global.DistribuidorDestacado = DistribuidorDestacado;
global.DistribuidorDestacadoAreas = DistribuidorDestacadoAreas;
global.DistribuidorMultiple = DistribuidorMultiple;
global.LogosSlider = LogosSlider;
global.DistributorSimple = DistributorSimple;
global.ModuleNews = ModuleNews;
global.ModuleLastNews = ModuleLastNews;
global.BloqueTexto = BloqueTexto;
global.BloqueTextoH1 = BloqueTextoH1;
global.SolucionesFixed = SolucionesFixed;
global.Resultados = Resultados;
global.RelatedItems = RelatedItems;
global.error404 = error404;
global.websAcciona = websAcciona;
global.withSolucionesNav = withSolucionesNav;
global.WysiwygNoticia = WysiwygNoticia;
global.WysiwygEmpleo = WysiwygEmpleo;
global.NewsList = NewsList;
global.HeaderLanding = HeaderLanding;
global.HeaderLandingOwnDomain = HeaderLandingOwnDomain;
global.FooterLanding = FooterLanding;
global.FooterLandingOwnDomain = FooterLandingOwnDomain;
global.ProjectsList = ProjectsList;
global.EquipoDirectivo = EquipoDirectivo;
global.EquipoDirectivoDestacado = EquipoDirectivoDestacado;
global.DownloadsList = DownloadsList;
global.TextWithCta = TextWithCta;
global.WorkDay = WorkDay;
global.WorkDayDetalleOferta = WorkDayDetalleOferta;
global.OwnTechList = OwnTechList;
global.ProyectosDestacados = ProyectosDestacados;
global.ExternalArticlesSlider = ExternalArticlesSlider;
global.BingResults = BingResults;
global.FormularioSimple = FormularioSimple;
global.SolucionesStoryFinal = SolucionesStoryFinal;
import React, { useEffect, useRef } from 'react';
//import { gsap } from 'gsap';
import { useHeroParallax } from 'React/custom_hooks';
import IconsMap from './_iconsMap';

import './styles.scss';

const HeroImage = ({
    className,
    media,
    countryProject,
    titleProject,
    cifraProject,
    unitProject,
    descriptionProject,
    projectSolution,
    otherProjectSolution,
    title,
    body,
    cta,
    hasVeil,
    combined
}) => {
    const imgContainer = React.createRef();

    let app = useRef(null);
    //let heading = useRef(null);
    //let text = useRef(null);
    //let button = useRef(null);
    //let separator = useRef(null);



    useHeroParallax('.component__hero-image');

    //const tl = gsap.timeline();

    useEffect(() => {
        if (app != null) {
            let img = new Image();
            img.src = `${media ? media.url : ''}`;

            // controlamos con esto que la imagen est� cargada y entonces la desvelamos
            img.onload = function () {
                app.classList.add('is-loaded');
                //gsap.to(app, { visibility: 'visible' }, 1);

                //tl.fromTo(imgContainer.current, 3, { scale: 2, x: 200 }, { scale: 1, x: 0 })
                //    .from(heading, 1, { opacity: 0, y: 40, delay: 2 }, 0.2)
                //    .to(heading, 2, { opacity: 1, y: 40, delay: 2 }, 0.2)
                //    .from(text, 1, { opacity: 0, y: 40, delay: 2 }, 0.2)
                //    .to(text, 2, { opacity: 1, y: 40, delay: 2 }, 0.2)
                //    .from(button, 1, { opacity: 0, y: 40, delay: 2 }, 0.6)
                //    .to(button, 2, { opacity: 1, y: 40, delay: 2 }, 0.6)
                //    .from(separator, 1, { width: '0', delay: 2 }, 0.6)
                //    .to(separator, 2, { width: '100%', delay: 2 }, 0.6);
            };

            if (img.complete) img.onload();
        }
    }),
        [];


    return (
        <div
            ref={(el) => (app = el)}
            className={`module grid full-width component__hero-image ${className} is-loaded`}
            data-combined={combined}
        >
            {media && media.url && (
                <div ref={imgContainer} className="backgrounds full-width" style={{ backgroundImage: `url(${media.url})` }}>
                    <div className={`img-container ${hasVeil ? '' : 'has-veil'}`}></div>
                </div>
            )}

            {media && media.videoId && <div className="video-container"></div>}
            <div className="content-blocks">
                {title && (
                    <h2 className="title title--xl">
                        {title}
                    </h2>
                )}
                {body && (
                    <div className="body body--l" dangerouslySetInnerHTML={{ __html: body }}>
                    </div>
                )}
                {cta && (
                    <a href={cta.link} className="circled-arrow-link" target={cta.target}>
                        <svg width="94" height="94" version="1.1" viewBox="0 0 94 94" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M56.25,40 L63.6746212,47.4246212 L56.25,54.8492424 L55.5428932,54.1421356 L62.01,47.674 L33,47.6746212 L33,46.6746212 L61.51,46.674 L55.5428932,40.7071068 L56.25,40 Z"
                                id="Combined-Shape"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                            ></path>

                            <circle fill="none" id="circle1" strokeWidth="1" stroke="#FFFFFF" cx="48" cy="47" r="44"></circle>
                        </svg>
                    </a>
                )}
                {cifraProject && (
                    <h2 className="cifras--xl">
                        {cifraProject}
                        <span className="cifras--m">{unitProject}</span>
                    </h2>
                )}
                {descriptionProject && <p className="title--xs">{descriptionProject}</p>}
                <div className="separator"></div>

                {titleProject && (
                    <div className="project-detail-footer">
                        <h2 className="title--m">{titleProject}</h2>
                        <div className="project-detail-footer__desc">
                            <span className="category-project taglabel">
                                {IconsMap[projectSolution.icon]}{projectSolution.name}
                                {/*categoryProject*/}
                            </span>
                            {countryProject && <span className="country-project taglabel">{countryProject}</span>}
                            {otherProjectSolution && otherProjectSolution.length > 0 && (
                                <>
                                    {otherProjectSolution.map((item, idx) => {
                                        return (
                                            <span className="country-project taglabel" key={`other-solution-${idx}`}>
                                                {IconsMap[item.icon]}{item.name}
                                            </span>
                                        );
                                    })}
                                </>

                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeroImage;

const useTranslate = (data) => {

    const nameTranslations = {
        AGUA: 'WATER',
        FINANCIERAS: 'FINANCIAL',
        ENERGÍA: 'ENERGY',
        INMOBILIARIA: 'REALESTATE',
        TRANSPORTE: 'TRANSPORT',
        CIUDADES: 'CITIES',
        SOCIALES: 'SOCIAL',
        //NORUEGO
        ENERGI: 'ENERGY',
        SOSIALT: 'SOCIAL',
        VANN: 'WATER',
        BYER: 'CITIES',
        EIENDOM: 'REALESTATE',
        FINANS: 'FINANCIAL',
        //PORTUGUES
        ENERGIA: 'ENERGY',
        SOCIAIS: 'SOCIAL',
        ÁGUA: 'WATER',
        CIDADES: 'CITIES',
        IMOBILIÁRIA: 'REALESTATE',
        FINANCEIRAS: 'FINANCIAL',
        //Inglés
        FINANCE: 'FINANCIAL',
    };

    const translateName = (name) => {
        return nameTranslations[name] || name;
    };

    if (typeof data === 'string') {
        return translateName(data);
    } else if (Array.isArray(data)) {
        return data.map(translateName);
    } else {
        console.log('Translate error');
        return data;
    }
};

export default useTranslate;

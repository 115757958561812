import React, { useState, useEffect } from 'react';
import { Breadcrumbs, WysiwygEmpleo } from 'React/components';

const WorkDayDetalleEmpleo = ({ model, culture, labelSolicitarEmpleo, labelDe }) => {

    const [idOfferState, setIdOfferState] = useState('');
    const [offerDetailState, setOfferDetailState] = useState('');

    const [idOffer, setIdOffer] = useState('');

    const getOfferDetail = (id) => {

        var url = `/api/CmsController/GetOfferById?language=${culture}&id=${id}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {

                    var offer = JSON.parse(xhrPages.responseText);

                    setOfferDetailState(offer);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);
    };

    function findGetParameter(parameterName) {
        var result = '',
            tmp = [];
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }

    
    //const getIdOffer = () => {
    //    var idOffer = '';
    //    if (window !== null) {
    //        var url = window.location.href;
    //        var urlIdOffer = url.match('#(.+)');
    //        if (urlIdOffer !== null && urlIdOffer.length > 0) {

    //            setIdOfferState(urlIdOffer[1]);
    //            idOffer = urlIdOffer[1];

    //        }
    //    }
    //    return idOffer;
    //};

    useEffect(() => {
        var id = findGetParameter("id");
        setIdOffer(id);
        if (idOfferState === '' || idOfferState === undefined) {
            getOfferDetail(id);
        }
    }, [setOfferDetailState]);

    return (
        <div className=''>
            <Breadcrumbs culture={culture} type='fixed--empleo' cta={model.cta ? model.cta : ''} backLink={model.backLink ? model.backLink : ''}
                firstLevel={model.firstLevel ? model.firstLevel : ''} secondLevel={model.secondLevel ? model.secondLevel : ''}
                thirdLevel={model.thirdLevel ? model.thirdLevel : ''} pageTitle={offerDetailState.title} labelSolicitarEmpleo={labelSolicitarEmpleo}
                jobDate={offerDetailState.date} sector={offerDetailState.divisionName} jobId={idOffer}  jobPlace={offerDetailState.location} url={offerDetailState.url} labelDe={labelDe} />
            <WysiwygEmpleo description={offerDetailState.description}/>
        </div>
    );

};

export default WorkDayDetalleEmpleo;








import React, { useState, useEffect } from 'react';
import DefaultTable from './DefaultTable';
import SolutionAreas from './SolutionAreas';
import SolutionAreasHighlights from './SolutionAreasHighlights';
import SolutionAreasWithText from './SolutionAreasWithText';

const Table = (props) => {

    const [culture, setCulture] = useState('');

    useEffect(() => {
        const htmlLang = document.documentElement.lang;
        setCulture(htmlLang);
    }, []);

    let titleText = '';

    switch (culture) {
        case 'en':
            titleText = 'Areas of activity';
            break;
        case 'no':
            titleText = 'Aktivitetsområder';
            break;
        case 'br':
            titleText = 'Áreas de atividade';
            break;
        default:
            titleText = 'Áreas de actividad';
            break;
    }



    const tableType = props.tableType;

    let tableComponent;

    switch (tableType) {
        case 'SolutionAreas':
            tableComponent = <SolutionAreas {...props} culture={culture} titleText={titleText} />;
            break;
        case 'SolutionAreasHighlights':
            tableComponent = <SolutionAreasHighlights {...props} culture={culture} titleText={titleText} />;
            break;
        case 'SolutionAreasWithText':
            tableComponent = <SolutionAreasWithText {...props} culture={culture} titleText={titleText} />;
            break;
        default:
            tableComponent = <DefaultTable {...props} culture={culture} titleText={titleText} />;
            break;
    }

    return <>{tableComponent}</>;
};

export default Table;

function useAnalytics(typePush, search, results) {


    if (typePush === 'search') {

        var query = search[1].slice(0, -1).substring(1).trim().toLowerCase();
        var numResults = results.length;

        var dataObject = {
            event: "ga_event",
            event_name: "search",
            event_category: "search",
            event_action: query,
            event_label: numResults.toString(),   //string
            event_value: parseFloat(numResults.toFixed(2)),  //2 decimals
            quantity: parseFloat(numResults.toFixed(2)), // 2 decimals
            search_results: numResults.toString(),  //string
            search_term: query
        }
        dataLayer.push(dataObject);
    }
}

export default useAnalytics;
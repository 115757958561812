import React, { useState } from 'react';
import { Lightbox, Wysiwyg } from 'React/components';
import { ReactComponent as Arrow } from 'Icons/ArrowLong.svg';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';
import { ReactComponent as Check } from 'Icons/valid-16.svg';
import { ReactComponent as CheckBig } from 'Icons/valid-32.svg';
import { ReactComponent as Cross } from 'Icons/cross.svg';
import { ReactComponent as CrossBig } from 'Icons/cross-32.svg';
import { rndKeyGen } from 'React/helpers';
import ReCAPTCHA from "react-google-recaptcha";


import Fade from 'react-reveal/Fade';

import './Styles.scss';

const SubscriptionForm = ({ action = '/Subscribe/', title = 'Suscríbete a nuestra newsletter',
    descr = 'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.',
    confirmationTitle, confirmationMsg, color = 'red', culture = 'es', errorMsg, registrationErrorMsg, popup,
    labelIAcept,
    labelDataProtection,
    labelWriteYourEmail,
    labelConfirmationMsgTitle,
    labelConfirmationMsgDescription,
    labelSubscribedMsgTitle,
    labelSubscribedMsgDescription,
    labelcheckboxError,
    mailchimpList,
    isFooter }) => {

    const [checkboxState, setCheckboxState] = useState(false);
    const [checkboxErrorState, setCheckboxErrorState] = useState(false);
    const [formStep, setFormStep] = useState('initial');
    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');
    const [visiblePrivacyPolicyLightbox, setVisiblePrivacyPolicyLightbox] = useState(false);
    const [privacyPolicyLightboxContent, setPrivacyPolicyLightboxContent] = useState('test');
    const [reCaptchaValue, setReCaptchaValue] = useState(false);


    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

    const handlePrivacyPolicyLightboxClick = (content) => {
        if (content !== '') {
            setPrivacyPolicyLightboxContent(content);
            setVisiblePrivacyPolicyLightbox(true);
        }
    };

    const toggleCheckbox = () => {
        setCheckboxState((oldState) => !oldState);
    };

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function onChangeReCaptcha(value) {
        setReCaptchaValue(value);
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const { email_to_subscribe, conditions_accepted } = event.target.elements;

        var params = `email_address=${email_to_subscribe.value}&language=${culture.toUpperCase()}${mailchimpList ? "&country=" + mailchimpList : ""}`;

        var error = false;
        var formFieldError = false;
        var formCheckBoxError = false;

        if (email_to_subscribe.value.trim() === '' || !validateEmail(email_to_subscribe.value)) {
            setFormStep('error');
            error = true;
            formFieldError = true;
        }
        else {
            setFormStep('initial');
        }

        if (!checkboxState) {
            setCheckboxErrorState(true);
            error = true;
            formCheckBoxError = true;
        }
        else {
            setCheckboxErrorState(false);
        }

        if (!error && reCaptchaValue) {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "newsletter_submit",
                event_category: "newsletter",
                event_action: "submit",
                event_label: undefined,
                event_value: undefined
            });

            const xhrPages = new XMLHttpRequest();
            xhrPages.open('POST', action, true);
            xhrPages.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhrPages.onload = function (e) {
                if (xhrPages.readyState === 4) {
                    if (xhrPages.status === 200) {
                        var response = JSON.parse(xhrPages.responseText);
                        if (response.includes("Error")) {
                            setFormStep('registrationError');
                            setVisibleLightbox(true);
                        }
                        else if (response.includes("Existente")) {
                            setFormStep('registrationErrorSubscribed');
                            setVisibleLightbox(true);
                        }
                        else {
                            setFormStep('submited');

                            window.dataLayer.push({
                                event: "ga_event",
                                event_name: "newsletter_confirmation",
                                event_category: "newsletter",
                                event_action: "confirmation",
                                event_label: undefined,
                                event_value: undefined
                            });

                            setVisibleLightbox(true);
                        }
                    } else {
                        console.error(xhrPages.statusText);
                    }
                }
            }.bind(this);

            xhrPages.send(params);
        } else {
            if (formCheckBoxError) {

                window.dataLayer.push({
                    event: "ga_event",
                    event_name: "error_form",
                    event_category: "error form",
                    event_action: "politica de privacidad",
                    event_label: "debe aceptar la politica de privacidad",
                    event_value: undefined,
                    location: "politica de privacidad",
                    message: "debe aceptar la politica de privacidad"
                });
            }
            if (formFieldError) {

                window.dataLayer.push({
                    event: "ga_event",
                    event_name: "error_form",
                    event_category: "error form",
                    event_action: "email",
                    event_label: "debe indicar el email",
                    event_value: undefined,
                    location: "email",
                    message: "debe indicar el email"
                });
            }
        }
    };

    return (
        <>
            <form
                action={action}
                onSubmit={handleSubmit}
                className={`subscribtion-form ${isFooter ? '' : 'module'} ${color === 'white' ? 'white-form' : ''}`}
            >
                <div className="form-container grid">
                    <div className="grid-container grid full-width">
                        <div className="grid-left">
                            <h3 className="form-title title--m uppercase">{title}</h3>
                            {descr && (
                                <div className="form-descr body--m" dangerouslySetInnerHTML={{ __html: descr }} ></div>
                            )}
                        </div>
                        <div className="grid-right">
                            <div className="input-button">
                                <input type="text" name="email_to_subscribe" title="suscríbete" placeholder={labelWriteYourEmail} />
                                <button className="form-submit" type="submit">
                                    <Arrow />
                                </button>
                            </div>
                            <hr />

                            <div className="faux-flex">
                                <input
                                    type="checkbox"
                                    id="conditions_accepted"
                                    value="true"
                                    checked={checkboxState === true}
                                    onChange={toggleCheckbox}
                                    name="conditions_accepted"
                                />
                                <div className="faux-checkbox" onClick={toggleCheckbox} aria-hidden="true">
                                    <Chevron className="checkbox-tick" />
                                </div>
                                {popup &&
                                    <label
                                        className="body--s for-terms-checkbox"

                                        onClick={toggleCheckbox}
                                    >{labelIAcept}
                                        <a href="#" className="link--white" onClick={() => handlePrivacyPolicyLightboxClick(popup.text)}> {labelDataProtection}</a>
                                    </label>
                                }
                            </div>
                            <div className="feedback-message" data-is-visible={formStep === 'error'}>
                                <Cross className="validation-fail" width="16" height="16" />
                                <p className="body--m">{errorMsg}</p>
                            </div>
                            {checkboxErrorState &&
                                <div className="feedback-message">
                                    <Cross className="validation-fail" width="16" height="16" />
                                    <p className="body--m">{labelcheckboxError}</p>
                                </div>
                            }

                            <ReCAPTCHA
                                className="reCaptcha"
                                sitekey="6LeHDZwkAAAAAOJ7KFBe-x-ntDwXVCvWvemX4lbp"
                                onChange={onChangeReCaptcha}
                                hl={culture === "br" ? "pt-br" : culture} />
                        </div>


                        <div className="black">
                            <Lightbox
                                key={rndKeyGen()}
                                isVisible={visiblePrivacyPolicyLightbox}
                                closeMethod={() => setVisiblePrivacyPolicyLightbox(null)}
                            >
                                <Wysiwyg html={privacyPolicyLightboxContent} />
                            </Lightbox>
                            <Lightbox
                                key={rndKeyGen()}
                                isVisible={visibleLightbox}
                                closeMethod={() => setVisibleLightbox(null)}
                            >
                                <div className="feedback-message" data-is-visible={formStep === 'submited'}>
                                    <div className="centerIcon"><CheckBig width="48" height="48" /></div>
                                    <h3>{confirmationTitle ? confirmationTitle.toUpperCase() : labelConfirmationMsgTitle}</h3>
                                    <p className="body--m">{confirmationMsg ? confirmationMsg : labelConfirmationMsgDescription}</p>
                                </div>
                                <div className="feedback-message" data-is-visible={formStep === 'registrationErrorSubscribed'}>
                                    <div className="centerIcon"><CheckBig width="48" height="48" /></div>
                                    <h3>{labelSubscribedMsgTitle}</h3>
                                    <p className="body--m">{labelSubscribedMsgDescription}</p>
                                </div>
                                <div className="feedback-message" data-is-visible={formStep === 'registrationError'}>
                                    <div className="centerIcon"><CrossBig className="validation-fail" width="48" height="48" /></div>
                                    <p className="body--m">{registrationErrorMsg}</p>
                                </div>
                            </Lightbox>
                        </div>
                    </div>
                </div>

            </form>


        </>
    );
};

export default SubscriptionForm;

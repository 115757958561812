// this component renders its children and adds context-nav component with position sticky below
// it also passes the 'combined' prop that is used to remove bottom margin on children components
import React from 'react';
const { useEffect } = React;
import { ReactComponent as EnergiaIcon } from 'Icons/energia-viento.svg';
import IconsMap from './_iconsMap';

import './withSolucionesNav.scss';

const withSolucionesNav = ({ children, type, interior, routes, icon, solution, subMenu }) => {

    function getScrollParent(element, includeHidden) {
        var style = getComputedStyle(element),
            excludeStaticParent = style.position === "absolute",
            overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

        if (style.position !== "fixed")
            for (var parent = element; (parent = parent.parentElement);) {
                style = getComputedStyle(parent);
                if (excludeStaticParent && style.position === "static")
                    continue;
                if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
                    return parent;
            }

        return window
    }

    // mount 
    useEffect(() => {
        const stickyNav = document.getElementById('stickyNav');
        const body = getScrollParent(stickyNav, true);

        const scrollCallback = () => {
            if (!stickyNav._originalOffsetTop) {
                stickyNav._originalOffsetTop = stickyNav.offsetTop;
            }
            if (!stickyNav._recordedSticky && stickyNav.offsetTop > stickyNav._originalOffsetTop) {
                stickyNav._recordedSticky = true;
                const title = window.location.pathname.split('/').length > 0 ? window.location.pathname.split('/')[1]
                    : stickyNav.querySelector('span') ? stickyNav.querySelector('span').textContent : '';
                const label = window.location.pathname;

                window.dataLayer.push({
                    event: "ga_event",
                    event_name: "sticky-menu",
                    event_category: "sticky-menu",
                    event_action: title,
                    event_label: label,
                    event_value: undefined,
                    link_text: label,
                    link_url: window.location.href,
                    location: title,
                });
            }
        };
        body.addEventListener('scroll', scrollCallback);

    }, [])

    if (type === 'single' || subMenu.length === 1) {
        return (
            <>
                <nav className="module context-nav context-nav--single">
                    <div className="title--s is-active">{IconsMap[icon]}<span>{subMenu[0]._contentName}</span></div>
                </nav>
            </>
        );
    } else {
        return (
            <>
                <nav id="stickyNav" className="module context-nav">
                    {interior &&
                        <div className="title--s interior is-active"><a href={subMenu[0]._contentPath}>{IconsMap[icon]}<span>{subMenu[0]._contentName}</span></a></div>
                    }
                    {!interior &&
                        <div className="title--s is-active">{IconsMap[icon]}<span>{subMenu[0]._contentName}</span></div>
                    }
                    {
                        subMenu.slice(1).map((item, idx) => {
                            return (
                                <a href={item._contentPath} className={`title--xs ${item.isActive ? 'menu-active' : ''}`}>{item._contentName}</a>
                            );
                        })
                    }
                </nav>
            </>
        )
    }

};

export default withSolucionesNav;

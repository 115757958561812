import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { TagTitle, Link } from 'React/components';

import './styles.scss';

const Distribuidor2Col = ({ tagLine, subModules, anchor }) => {
    let moduleRef = React.createRef();
    //useSlowFade(moduleRef);
    const refList = subModules.map((item) => React.createRef());

    const onHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.add('is-hovered');
    };

    const notHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.remove('is-hovered');
    };

    return (
        <div ref={moduleRef} className="module grid distribuidor-2-col">
            {tagLine && <TagTitle style="slow--y" title={tagLine} />}
            <div className="distribuidor-2-col-container">
                {subModules.map((item, idx) => {
                    const { titleBlock, cta, image, title, leadText, download, linkItem, fileParams } = item;

                    return (
                        <div key={`data-point-${idx}`} className=" slow--y distribuidor-2-col-item" ref={refList[idx]}>
                            {image && (
                                <div className="image-container slow--y">
                                    <img src={image.url} alt={image.alt} title={image.title} />
                                </div>
                            )}

                            {titleBlock && (
                                <h2 className="title--xs mb-2 slow--y">
                                    {linkItem &&
                                        <a data-ref-id={idx} href={linkItem ? linkItem.link : cta ? cta.link : '#'} target={linkItem ? linkItem.target : ''}>
                                            {titleBlock}
                                        </a>
                                    }
                                    {!linkItem &&
                                        <>{titleBlock}</>
                                    }
                                </h2>
                            )}

                            {title && (
                                <h2 className="title--m title slow--y">
                                    {linkItem &&
                                        <a data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} href={linkItem ? linkItem.link : cta ? cta.link : '#'} target={linkItem ? linkItem.target : ''}>
                                            {title}
                                        </a>
                                    }
                                    {!linkItem &&
                                        <>{title}</>
                                    }
                                </h2>
                            )}

                            <div className="lead-text-container slow--y">
                                {leadText &&
                                    <div className="lead-text body--m" dangerouslySetInnerHTML={{ __html: leadText }}></div>
                                }

                                {download && (
                                    <div className="main-text-paragraph slow--y">
                                        <Link label={download.name ? download.name : download.title} path={download.umbracoFile ? download.umbracoFile : download.file} type="download" bytes={download.umbracoBytes} extension={download.umbracoExtension} />
                                    </div>
                                )}

                                {/*linkItem && (
                                    <div className="main-text-paragraph slow--y">
                                        <Link label={linkItem.caption} path={linkItem.link} target={linkItem.target} type="arrow" />
                                    </div>
                                )*/}

                                {cta && (
                                    <div className="slow--y">
                                        <Link data-ref-id={idx} label={cta.caption} path={cta.link} onMouseOut={notHover} onMouseOver={onHover} type="arrow" target={cta.target} />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
                <span className="custom-anchor-small" id={anchor} />
            </div>
        </div>
    );
};

export default Distribuidor2Col;

import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { CheckboxGroup, Checkbox, RadioButton, TextInput, SelectInput, TextArea, NumberInput, Button, Lightbox, Wysiwyg, SquareCheckbox, Uploader } from 'React/components';
import { ReactComponent as IconCheck } from 'Icons/valid-16.svg';
import './styles.scss';
import { validateEmail, validatedni, rndKeyGen } from 'React/helpers';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';
//import { Tooltip } from 'antd';

// firstStep prop is used for internal validation only
const ContactFormPoliticaPrivacidad = ({ firstStep = 'initial', title, description, popup, translations, closeLightbox, accessRight, rectificationRight, suppresionRight, treatmentRight, dataRight, oppositionRight }) => {
    const [formStep, setFormStep] = useState(firstStep);
    const [nameError, setNameError] = useState('');
    const [nameRepresentedError, setNameRepresentedError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [surnameRepresentedError, setSurnameRepresentedError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [dniError, setDniError] = useState('');
    const [dniRepresentedError, setDniRepresentedError] = useState('');
    const [actOwnRightError, setActOwnRightError] = useState('');
    const [rightsError, setRightsError] = useState('');

    const [checkboxError, setCheckboxError] = useState('');
    const [checkboxState, setCheckboxState] = useState(false);
    const [checkboxStateConstruction, setCheckboxStateConstruction] = useState(false);
    const [checkboxStateConcesions, setCheckboxStateConcesions] = useState(false);
    const [checkboxStateWater, setCheckboxStateWater] = useState(false);
    const [checkboxStateServices, setCheckboxStateServices] = useState(false);
    const [checkboxStateEnergy, setCheckboxStateEnergy] = useState(false);
    const [checkboxStateRealEstate, setCheckboxStateRealEstate] = useState(false);
    const [checkboxStateBestinver, setCheckboxStateBestinver] = useState(false);
    const [checkboxStateAccionaSA, setCheckboxStateAccionaSA] = useState(false);

    const [checkboxStateCandidato, setCheckboxStateCandidato] = useState(false);
    const [checkboxStateEmpleado, setCheckboxStateEmpleado] = useState(false);
    const [checkboxStateProveedor, setCheckboxStateProveedor] = useState(false);
    const [checkboxStateCliente, setCheckboxStateCliente] = useState(false);
    const [checkboxStateSocio, setCheckboxStateSocio] = useState(false);
    const [checkboxStateVisitante, setCheckboxStateVisitante] = useState(false);
    const [checkboxStateOtro, setCheckboxStateOtro] = useState(false);

    const [checkboxStateDerechoAcceso, setCheckboxStateDerechoAcceso] = useState(false);
    const [checkboxStateDerechoRectificacion, setCheckboxStateDerechoRectificacion] = useState(false);
    const [checkboxStateDerechoSupresion, setCheckboxStateDerechoSupresion] = useState(false);
    const [checkboxStateDerechoTratamiento, setCheckboxStateDerechoTratamiento] = useState(false);
    const [checkboxStateDerechoDatos, setCheckboxStateDerechoDatos] = useState(false);
    const [checkboxStateDerechoOposicion, setCheckboxStateDerechoOposicion] = useState(false);

    const [reCaptchaValue, setReCaptchaValue] = useState(false);
    const [represented, setRepresented] = useState(false);
    const [relationshipOther, setRelationshipOther] = useState(false);

    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');

    const [filesList, setFilesList] = useState([]);
    const [filesRepresentedList, setFilesRepresentedList] = useState([]);

    const handlePrivacyPolicyLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

    const {
        culture,
        labelName,
        labelSurname,
        labelDNI,
        labelEmailContact,
        labelActaOwnRight,
        labelYes,
        labelNo,
        labelNameRepresented,
        labelSurnameRepresented,
        labelDniRepresented,
        labelRelationshipAcciona,
        optionConstruccion,
        optionConcesiones,
        optionAgua,
        optionServicios,
        optionEnergia,
        optionInmobiliaria,
        optionBodegas,
        optionBestinver,
        opcionAccionaSA,
        labelWhatRight,
        labelRightAccess,
        labelRightRectification,
        labelRightSuppression,
        labelRightTreatment,
        labelRightData,
        labelOpposition,
        labelAdditionalInfo,
        labelRightOpposition,
        labelActivityRights,
        optionCandidato,
        optionEmpleado,
        optionProveedor,
        optionCliente,
        optionSocio,
        optionVisitante,
        optionOtro,
        labelNameValidation,
        labelSurnameValidation,
        labelDNIValidation,
        labelContactEmailValidation,
        labelEmailValidation,
        labelRightsValidation,
        labelSend,
        labelCancel,
        labelFormSendOKTitle,
        labelFormSendOKDescription,
        labelIAcept,
        labelDataProtection,
        labelcheckboxError,
        labelOptionValidation,
        labelInputUpload,
        labelDocumentRepresented,
        labelDocumentRepresentedValidation
    } = translations;

    const radioButtonEvent = (value) => {
        if (value === labelNo) setRepresented(true);
        else setRepresented(false);

        setActOwnRightError('');
    };

    const onChangeUploader = (files) => {
        //var files = filesList;
        //files.push(file);
        //setFilesList({
        //    filesList: [...filesList, files[0]]
        //});
        setFilesList((prevState) => {
            const newState = [...prevState];

            for (var i = 0; i < files.length; i++) {
                newState.push(files[i]);
            }            

            return newState;
        });
        //setFilesList(file);
    };

    const onChangeUploaderRepresented = (files) => {
        //var files = filesList;
        //files.push(file);
        setFilesRepresentedList((prevState) => {
            const newState = [...prevState];

            for (var i = 0; i < files.length; i++) {
                newState.push(files[i]);
            }

            return newState;
        });
        //setFilesRepresentedList(file);
    };

    const handleSubmit = (event) => {
        var validationErrors = false;

        event.preventDefault();
        var name = event.target.name.value;
        var surname = event.target.surname.value;
        var dni = event.target.dni.value;
        var email = event.target.email.value;
        var ownRight = event.target.ownRight.value;
        var nameRepresented;
        var surnameRepresented;
        var otherRelationship;
        var additionalInfo = event.target.additionalInfo.value;

        if (checkboxStateOtro) otherRelationship = event.target.otherRelationship.value;

        if (!checkboxStateDerechoAcceso && !checkboxStateDerechoDatos && !checkboxStateDerechoOposicion && !checkboxStateDerechoRectificacion && !checkboxStateDerechoSupresion && !checkboxStateDerechoTratamiento) {
            validationErrors = true;
            setRightsError(labelRightsValidation);
        }
        else {
            setRightsError('');
        }

        if (!name) {
            validationErrors = true;
            setNameError(labelNameValidation);
        }
        else {
            setNameError('');
        }

        if (!surname) {
            validationErrors = true;
            setSurnameError(labelSurnameValidation);
        }
        else {
            setSurnameError('');
        }

        if (email) {
            if (!validateEmail(email)) {
                validationErrors = true;
                setEmailError(labelEmailValidation);
            }
            else {
                setEmailError('');
            }
        }
        else {
            validationErrors = true;
            setEmailError(labelContactEmailValidation);
        }

        //if (dni) {
        //    if (!validatedni(dni)) {
        //        validationErrors = true;
        //        setDniError(labelDniValidation);
        //    }
        //    else {
        //        setDniError('');
        //    }
        //}
        if (!dni) {
            validationErrors = true;
            setDniError(labelDNIValidation);
        }
        else {
            setDniError('');
        }

        if (!ownRight) {
            validationErrors = true;
            setActOwnRightError(labelOptionValidation);
        }
        else {
            setActOwnRightError('');
        }

        if (represented) {
            nameRepresented = event.target.nameRepresented.value;

            if (!nameRepresented) {
                validationErrors = true;
                setNameRepresentedError(labelNameValidation);
            }
            else {
                setNameRepresentedError('');
            }

            surnameRepresented = event.target.surnameRepresented.value;

            if (!surnameRepresented) {
                validationErrors = true;
                setSurnameRepresentedError(labelSurnameValidation);
            }
            else {
                setSurnameRepresentedError('');
            }

            if (!filesRepresentedList.length > 0) {
                validationErrors = true;
                setDniRepresentedError(labelDocumentRepresentedValidation);
            }
            else {
                setDniRepresentedError('');
            }
        }

        if (!checkboxState) {
            validationErrors = true;
            setCheckboxError(labelcheckboxError);
        }
        else {
            setCheckboxError('');
        }

        if (!reCaptchaValue) {
            validationErrors = true;
        }

        if (!validationErrors) {
            var accionaRelationship = `${checkboxStateCandidato ? optionCandidato + '|' : ''}${checkboxStateEmpleado ? optionEmpleado + '|' : ''}${checkboxStateProveedor ? optionProveedor + '|' : ''}${checkboxStateCliente ? optionCliente + '|' : ''}${checkboxStateSocio ? optionSocio + '|' : ''}${checkboxStateVisitante ? optionVisitante + '|' : ''}${checkboxStateOtro ? optionOtro + ': ' + otherRelationship + '|' : ''}`;

            var activities = `${checkboxStateConstruction ? optionConstruccion + '|' : ''}${checkboxStateConcesions ? optionConcesiones + '|' : ''}${checkboxStateWater ? optionAgua + '|' : ''}${checkboxStateServices ? optionServicios + '|' : ''}${checkboxStateEnergy ? optionEnergia + '|' : ''}${checkboxStateRealEstate ? optionInmobiliaria + '|' : ''}${checkboxStateBestinver ? optionBestinver + '|' : ''}${checkboxStateAccionaSA ? opcionAccionaSA + '|' : ''}`;

            var rights = `${checkboxStateDerechoAcceso ? labelRightAccess + '|' : ''}${checkboxStateDerechoRectificacion ? labelRightRectification + '|' : ''}${checkboxStateDerechoSupresion ? labelRightSuppression + '|' : ''}${checkboxStateDerechoTratamiento ? labelRightTreatment + '|' : ''}${checkboxStateDerechoDatos ? labelRightData + '|' : ''}${checkboxStateDerechoOposicion ? labelRightOpposition + '|' : ''}`;

            //var params = `name=${name}&surname=${surname}&dni=${dni}&email=${email}&ownRight=${ownRight}&nameRepresented=${nameRepresented}&surnameRepresented=${surnameRepresented}&accionaRelationship=${accionaRelationship}&activities=${activities}&rights=${rights}&files=${JSON.stringify(filesList)}`;
            var formData = new FormData();

            formData.append("name", name);
            formData.append("surname", surname);
            formData.append("dni", dni);
            formData.append("email", email);
            formData.append("ownRight", ownRight);
            formData.append("nameRepresented", nameRepresented);
            formData.append("surnameRepresented", surnameRepresented);
            formData.append("accionaRelationship", accionaRelationship);
            formData.append("activities", activities);
            formData.append("rights", rights);
            formData.append("additionalInfo", additionalInfo);
            
            for (var i = 0; i !== filesList.length; i++) {
                formData.append("files", filesList[i]);
            }
            for (var j = 0; j !== filesRepresentedList.length; j++) {
                formData.append("documentRepresented", filesRepresentedList[j]);
            }

            $.ajax(
                {
                    url: "/SendPrivacyPolicyFormMail/",
                    data: formData,
                    processData: false,
                    contentType: false,
                    type: "POST",
                    success: function (data) {
                        setFormStep('sent');
                    }
                }
            );
            //const xhrPages = new XMLHttpRequest();
            //xhrPages.open('POST', "/SendPrivacyPolicyFormMail/", true);
            ////xhrPages.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            ////xhrPages.setRequestHeader("Content-Type", "multipart/form-data");
            //xhrPages.onload = function (e) {
            //    if (xhrPages.readyState === 4) {
            //        if (xhrPages.status === 200) {
            //            setFormStep('sent');
            //        } else {
            //            console.error(xhrPages.statusText);
            //        }
            //    }
            //}.bind(this);

            //xhrPages.send(formData);
        }
    };

    const toggleCheckbox = () => {
        setCheckboxState((oldState) => !oldState);
    };

    const toggleCheckboxConstruction = () => {
        setCheckboxStateConstruction((oldState) => !oldState);
    };

    const toggleCheckboxConcesions = () => {
        setCheckboxStateConcesions((oldState) => !oldState);
    };

    const toggleCheckboxWater = () => {
        setCheckboxStateWater((oldState) => !oldState);
    };

    const toggleCheckboxServices = () => {
        setCheckboxStateServices((oldState) => !oldState);
    };

    const toggleCheckboxEnergy = () => {
        setCheckboxStateEnergy((oldState) => !oldState);
    };

    const toggleCheckboxRealEstate = () => {
        setCheckboxStateRealEstate((oldState) => !oldState);
    };

    const toggleCheckboxBestinver = () => {
        setCheckboxStateBestinver((oldState) => !oldState);
    };

    const toggleCheckboxAccionaSA = () => {
        setCheckboxStateAccionaSA((oldState) => !oldState);
    };


    const toggleCheckboxCandidato = () => {
        setCheckboxStateCandidato((oldState) => !oldState);
    };

    const toggleCheckboxEmpleado = () => {
        setCheckboxStateEmpleado((oldState) => !oldState);
    };

    const toggleCheckboxProveedor = () => {
        setCheckboxStateProveedor((oldState) => !oldState);
    };

    const toggleCheckboxCliente = () => {
        setCheckboxStateCliente((oldState) => !oldState);
    };

    const toggleCheckboxSocio = () => {
        setCheckboxStateSocio((oldState) => !oldState);
    };

    const toggleCheckboxVisitante = () => {
        setCheckboxStateVisitante((oldState) => !oldState);
    };

    const toggleCheckboxOtro = () => {
        setCheckboxStateOtro((oldState) => !oldState);
    };

    const toggleCheckboxDerechoAcceso = () => {
        setCheckboxStateDerechoAcceso((oldState) => !oldState);
    };
    const toggleCheckboxDerechoRectificacion = () => {
        setCheckboxStateDerechoRectificacion((oldState) => !oldState);
    };
    const toggleCheckboxDerechoSupresion = () => {
        setCheckboxStateDerechoSupresion((oldState) => !oldState);
    };
    const toggleCheckboxDerechoTratamiento = () => {
        setCheckboxStateDerechoTratamiento((oldState) => !oldState);
    };
    const toggleCheckboxDerechoDatos = () => {
        setCheckboxStateDerechoDatos((oldState) => !oldState);
    };
    const toggleCheckboxDerechoOposicion = () => {
        setCheckboxStateDerechoOposicion((oldState) => !oldState);
    };


    function onChangeReCaptcha(value) {
        setReCaptchaValue(value);
    }


    if (formStep === 'initial')
        return (
            <form className="module grid contact_form one-column" onSubmit={handleSubmit}>
                {title &&
                    <div className="form_header">
                        <legend className="form_title title--l">{title}</legend>
                        {description && <div className="form_desciption body--m" dangerouslySetInnerHTML={{ __html: description }} />}
                    </div>
                }
                <fieldset className="form_inputs">
                    {/* Datos Generales */}
                    <p className="form_section_title body--l"></p>
                    {/* Nombre */}
                    <TextInput label={labelName} errorMsg={nameError} name="name" required />
                    {/* apellidos */}
                    <TextInput label={labelSurname} errorMsg={surnameError} name="surname" required />
                    {/* DNI */}
                    <TextInput label={labelDNI} errorMsg={dniError} name="dni" required />
                    {/* Email */}
                    <TextInput label={labelEmailContact} errorMsg={emailError} name="email" required />
                    {/* ¿actua en su propio derecho? */}
                    <RadioButton
                        label={labelActaOwnRight}
                        name="ownRight"
                        errorMsg={actOwnRightError}
                        options={[{ value: labelYes, label: labelYes }, { value: labelNo, label: labelNo }]}
                        onChangeEvent={radioButtonEvent}
                        required
                    />

                    {/* Nombre  representado */}
                    {represented &&
                        <TextInput label={labelNameRepresented} errorMsg={nameRepresentedError} name="nameRepresented" required={true} />
                    }
                    {/* apellidos  representado */}
                    {represented &&
                        <TextInput label={labelSurnameRepresented} errorMsg={surnameRepresentedError} name="surnameRepresented" required={true} />
                    }
                    {/* DNI representado */}
                    {represented &&
                        <div>
                            <p className="taglabel">{labelDocumentRepresented} <span style={{ color: 'red' }}>*</span></p>
                        <Uploader fileList={filesRepresentedList} handleChange={onChangeUploaderRepresented} label={labelInputUpload} />
                        <span className="error_msg" style={{ marginTop: '23px' }} dangerouslySetInnerHTML={{ __html: dniRepresentedError }}></span>
                        </div>
                    }
                    <p className="form_section_title body--l"></p>
                    {/* Relacion con acciona */}
                    <p className="form_section_title body--l">{labelRelationshipAcciona}</p>
                    <SquareCheckbox name="candidato" label={optionCandidato} checkboxState={checkboxStateCandidato} toggleCheckbox={toggleCheckboxCandidato} />
                    <SquareCheckbox name="empleado" label={optionEmpleado} checkboxState={checkboxStateEmpleado} toggleCheckbox={toggleCheckboxEmpleado} />
                    <SquareCheckbox name="proveedor" label={optionProveedor} checkboxState={checkboxStateProveedor} toggleCheckbox={toggleCheckboxProveedor} />
                    <SquareCheckbox name="cliente" label={optionCliente} checkboxState={checkboxStateCliente} toggleCheckbox={toggleCheckboxCliente} />
                    <SquareCheckbox name="socio" label={optionSocio} checkboxState={checkboxStateSocio} toggleCheckbox={toggleCheckboxSocio} />
                    <SquareCheckbox name="visitante" label={optionVisitante} checkboxState={checkboxStateVisitante} toggleCheckbox={toggleCheckboxVisitante} />
                    <SquareCheckbox name="otro" label={optionOtro} checkboxState={checkboxStateOtro} toggleCheckbox={toggleCheckboxOtro} />
                    {/*<SelectInput label={labelRelationshipAcciona}
                        options={[optionCandidato, optionEmpleado, optionProveedor, optionCliente, optionSocio, optionVisitante, optionOtro]}
                        defaultValue={''} name="relationshipAcciona" event={relationshipEvent} />*/}
                    <p className="form_section_title body--l"></p>
                    {checkboxStateOtro &&
                        <TextArea label={''} name="otherRelationship" />
                    }
                    
                    <p className="form_section_title body--l">{labelActivityRights}</p>

                    <SquareCheckbox name="construction" label={optionConstruccion} checkboxState={checkboxStateConstruction} toggleCheckbox={toggleCheckboxConstruction} />
                    <SquareCheckbox name="concesions" label={optionConcesiones} checkboxState={checkboxStateConcesions} toggleCheckbox={toggleCheckboxConcesions} />
                    <SquareCheckbox name="water" label={optionAgua} checkboxState={checkboxStateWater} toggleCheckbox={toggleCheckboxWater} />
                    <SquareCheckbox name="services" label={optionServicios} checkboxState={checkboxStateServices} toggleCheckbox={toggleCheckboxServices} />
                    <SquareCheckbox name="energy" label={optionEnergia} checkboxState={checkboxStateEnergy} toggleCheckbox={toggleCheckboxEnergy} />
                    <SquareCheckbox name="realestate" label={optionInmobiliaria} checkboxState={checkboxStateRealEstate} toggleCheckbox={toggleCheckboxRealEstate} />
                    <SquareCheckbox name="bestinver" label={optionBestinver} checkboxState={checkboxStateBestinver} toggleCheckbox={toggleCheckboxBestinver} />
                    <SquareCheckbox name="accionasa" label={opcionAccionaSA} checkboxState={checkboxStateAccionaSA} toggleCheckbox={toggleCheckboxAccionaSA} />

                    <p className="form_section_title body--l">{labelWhatRight} <span style={{ color: 'red' }}>*</span></p>
                    <div className="rights">
                        <div className="rightItem">
                            <SquareCheckbox name="acceso" label={labelRightAccess} checkboxState={checkboxStateDerechoAcceso} toggleCheckbox={toggleCheckboxDerechoAcceso} />
                            {/*checkboxStateDerechoAcceso &&
                                <p dangerouslySetInnerHTML={{ __html: accessRight }} />
                            */}
                            <div className="infoContainer">
                                <div className="triggerInfo">i</div>
                                <div className="info" dangerouslySetInnerHTML={{ __html: accessRight }} />
                            </div>
                        </div>

                        <div className="rightItem">
                            <SquareCheckbox name="rectificacion" label={labelRightRectification} checkboxState={checkboxStateDerechoRectificacion} toggleCheckbox={toggleCheckboxDerechoRectificacion} />
                            {/*checkboxStateDerechoRectificacion &&
                                <p dangerouslySetInnerHTML={{ __html: rectificationRight }} />
                            */}
                            <div className="infoContainer">
                                <div className="triggerInfo">i</div>
                                <div className="info" dangerouslySetInnerHTML={{ __html: rectificationRight }} />
                            </div>
                        </div>
                        <div className="rightItem">
                            <SquareCheckbox name="supresion" label={labelRightSuppression} checkboxState={checkboxStateDerechoSupresion} toggleCheckbox={toggleCheckboxDerechoSupresion} />
                            {/*checkboxStateDerechoSupresion &&
                                <p dangerouslySetInnerHTML={{ __html: suppresionRight }} />
                            */}
                            <div className="infoContainer">
                                <div className="triggerInfo">i</div>
                                <div className="info" dangerouslySetInnerHTML={{ __html: suppresionRight }} />
                            </div>
                        </div>

                        <div className="rightItem">
                            <SquareCheckbox name="tratamiento" label={labelRightTreatment} checkboxState={checkboxStateDerechoTratamiento} toggleCheckbox={toggleCheckboxDerechoTratamiento} />
                            {/*checkboxStateDerechoTratamiento &&
                                <p dangerouslySetInnerHTML={{ __html: treatmentRight }} />
                            */}
                            <div className="infoContainer">
                                <div className="triggerInfo">i</div>
                                <div className="info" dangerouslySetInnerHTML={{ __html: treatmentRight }} />
                            </div>
                        </div>

                        <div className="rightItem">
                            <SquareCheckbox name="datos" label={labelRightData} checkboxState={checkboxStateDerechoDatos} toggleCheckbox={toggleCheckboxDerechoDatos} />
                            {/*checkboxStateDerechoDatos &&
                                <p dangerouslySetInnerHTML={{ __html: dataRight }} />
                            */}
                            <div className="infoContainer">
                                <div className="triggerInfo">i</div>
                                <div className="info" dangerouslySetInnerHTML={{ __html: dataRight }} />
                            </div>
                        </div>
                        <div className="rightItem">
                            <SquareCheckbox name="oposicion" label={labelRightOpposition} checkboxState={checkboxStateDerechoOposicion} toggleCheckbox={toggleCheckboxDerechoOposicion} />
                            {/*checkboxStateDerechoOposicion &&
                                <p dangerouslySetInnerHTML={{ __html: oppositionRight }} />
                                */}
                            <div className="infoContainer">
                                <div className="triggerInfo">i</div>
                                <div className="info" dangerouslySetInnerHTML={{ __html: oppositionRight }} />
                            </div>
                        </div>
                    </div>
                    <span className="error_msg" style={{ marginTop: '23px' }} dangerouslySetInnerHTML={{ __html: rightsError }}></span>

                    <TextArea label={labelAdditionalInfo} name="additionalInfo" />
                    <Uploader fileList={filesList} handleChange={onChangeUploader} label={labelInputUpload} />

                    <p className="form_section_title body--l"></p>
                    <div className="faux-flex">
                        <input
                            type="checkbox"
                            id="conditions_accepted"
                            value="true"
                            checked={checkboxState === true}
                            onChange={toggleCheckbox}
                            name="conditions_accepted"
                        />
                        <div className="faux-checkbox" onClick={toggleCheckbox} aria-hidden="true">
                            <Chevron className="checkbox-tick" />
                        </div>
                        {popup &&
                            <label
                                className="body--s for-terms-checkbox"

                                onClick={toggleCheckbox}
                            >{labelIAcept}
                                <a href="#" className="link--white" onClick={() => handlePrivacyPolicyLightboxClick(popup.text)}> {labelDataProtection}</a>
                            </label>
                        }

                        <Lightbox
                            key={rndKeyGen()}
                            isVisible={visibleLightbox}
                            closeMethod={() => setVisibleLightbox(null)}
                        >
                            <Wysiwyg html={lightboxContent} />
                        </Lightbox>
                    </div>
                    <span className="error_msg" style={{ marginTop: '23px' }} dangerouslySetInnerHTML={{ __html: checkboxError }}></span>

                    <ReCAPTCHA
                        className="reCaptcha"
                        sitekey="6LeHDZwkAAAAAOJ7KFBe-x-ntDwXVCvWvemX4lbp"
                        onChange={onChangeReCaptcha}
                        hl={culture ==="br" ? "pt-br": culture}
                    />
                    <div className="form_controls_wrapper">
                        <Button
                            type="submit"
                            className="form_submit"
                            label={labelSend}
                            size="button--lg"
                            style_type="red"
                        />
                        <Button className="form_close" label={labelCancel} size="button--lg" onClick={() => closeLightbox(null)} />
                    </div>
                </fieldset>
            </form>
        );

    if (formStep === 'sent')
        return (
            <div className="form_feedback">
                <h2 className="form_title title--l">{title}</h2>
                <div className="feedback-msg">
                    <div className="icon-container">
                        <IconCheck width="32" height="32" viewBox="-2 -4 16 16" />
                    </div>
                    <h3 className="body--l">{labelFormSendOKTitle}</h3>
                    <h4 className="body--m">{labelFormSendOKDescription}</h4>
                </div>
            </div>
        );
};

export default ContactFormPoliticaPrivacidad;

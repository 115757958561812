import React, { useRef } from 'react';

import { useStickyHeader } from 'React/custom_hooks';
import { Link } from 'React/components';

import './HeaderLanding.scss';
import LogoFull from 'Images/logos/logo_full.svg';

const HeaderLandingOwnDomain = ({ stickyHeader = true, logo, hrefLogo, homeLink, noSlogan, isOwnLanguage, changeLanguageText, changeLanguageLink }) => {
    const headerRef = useRef();

    // STICKY HEADER ON SCROLL UP
    useStickyHeader(headerRef, stickyHeader);

    return (
        <>
            {!logo && (

                <header className={`header-landing${isOwnLanguage ? " header-landing__lang" : ""}`} ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className={`logo${noSlogan ? ' logo-sainz' : ''}`} src={noSlogan ? '/static/images/logos/acciona-sainz-xe-team.jpg' : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>

                    {isOwnLanguage && (<a href={changeLanguageLink} className="header-landing__language">{changeLanguageText}</a>)}
                </header>
            )}

            {logo && (
                <header className={`page-header grid header-landing header-landing--logo${isOwnLanguage ? " header-landing-logo__lang" : " header-landing--logo"}`} ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={noSlogan ? Logo : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                    <Link path={hrefLogo ? hrefLogo.link : '/'} className="logo-home-right">
                        <img
                            className="logo"
                            src={logo.url}
                            alt={logo.name}
                            title={logo.title}
                        />
                    </Link>
                    {isOwnLanguage && (<a href={changeLanguageLink} className="header-landing__language-with-logo">{changeLanguageText}</a>)}
                </header>
            )}
        </>
    );
};

export default HeaderLandingOwnDomain;

import React, { useState } from 'react';
import { useSlowFade, useFade } from 'React/custom_hooks';
import { Link, Lightbox, ContactFormGreenEnergy, ContactFormPoliticaPrivacidad } from 'React/components';
import { rndKeyGen } from 'React/helpers';
import { ReactComponent as DownloadIcon } from 'Icons/download.svg';

import './styles.scss';

const FormularioSimple = ({
    model,
    formTranslation
}) => {


    const {
        title,
        ctaText,
        anchor,
        form,
        culture } = model;

    const [visibleLightbox, setVisibleLightbox] = useState(false);

    function RedLink() {

        var buttonStyle = {
            display: 'flex',
            backgroundColor: 'red',
            color: 'white',
            borderRadius: '30px',
            width: '25rem',
            height: '5rem',
            marginLeft: '21%',
            marginTop: '-6%',
            marginBottom: '3%',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            letterSpacing: '2px'
        }

        return (
            <a href="#" style={buttonStyle} onClick={() => handleLightboxClick()}
            >{ctaText}</a>
        );
    }


    const handleLightboxClick = () => {
        setVisibleLightbox(true);
    };

    const moduleRef = React.createRef();

    useSlowFade(moduleRef);
    useFade(moduleRef);


    return (
        <div ref={moduleRef}>
            {title && (
                <h3>
                    {title}
                </h3>
            )}
            {ctaText && <RedLink />
            }
            {
                form &&
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLightbox}
                    closeMethod={() => setVisibleLightbox(null)}
                >
                    {form.type === 'GreenEnergy' && <ContactFormGreenEnergy title={form.title} description={form.description} translations={formTranslation} closeLightbox={setVisibleLightbox} popup={form.popup} culture={culture} />}
                    {form.type === 'Politica Privacidad' && <ContactFormPoliticaPrivacidad title={form.title} description={form.description} translations={formTranslation} closeLightbox={setVisibleLightbox} popup={form.popup} culture={culture} accessRight={form.accessRight} rectificationRight={form.rectificationRight} suppresionRight={form.suppresionRight} treatmentRight={form.treatmentRight} dataRight={form.dataRight} oppositionRight={form.oppositionRight} />}
                </Lightbox>
            }
        </div >
    );
};

export default FormularioSimple;

import { useState, useEffect } from 'react';

function useCulture() {
    debugger;

    const [culture, setCulture] = useState(null);

    useEffect(() => {
        const htmlLang = document.documentElement.lang;
        setCulture(htmlLang);
    }, []);

    return culture;
}

export default useCulture;
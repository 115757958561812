import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useFade } from 'React/custom_hooks';

import './style.scss';

import { TagTitle, Link } from 'React/components';
import { ReactComponent as IconArrowRight } from 'Icons/ArrowLong.svg';

const AreasActividad = ({ title, module_cta, subModules, anchor }) => {
    const [activeFrame, setActiveFrame] = useState(1);

    const imagesContainerWidth = { width: subModules.length * 100 + 'vw' };
    const headerSliderComponent = React.createRef();
    const prevButton = React.createRef();
    const nextButton = React.createRef();

    // hovering on title triggers link icon effect
    const refList = subModules.map(() => React.createRef());

    const onHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId - 1)].current.classList.add('is-hovered');
    };

    const notHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId) - 1].current.classList.remove('is-hovered');
    };

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < subModules.length) {
            setActiveFrame(activeFrame + 1);
            pushSliderDatalayer();
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            pushSliderPrevDatalayer();
            setActiveFrame(activeFrame - 1);
        }
    };

    const endSliderDatalayer = () => {

        window.dataLayer.push({
            event: "ga_event",
            event_name: "slider_completed_energy",
            event_category: "slider completed energy",
            event_action: undefined,
            event_label: undefined,
            event_value: undefined,
            location: "energia"
        });

    };

    const pushSliderPrevDatalayer = () => {

        window.dataLayer.push({
            event: "ga_event",
            event_name: "image_scroll_energy",
            event_category: " image scroll energy",
            event_action: `${activeFrame - 2}:${subModules[activeFrame - 2].title}`,
            event_label: undefined,
            event_value: undefined,
            title: `${activeFrame - 2}:${subModules[activeFrame - 2].title}`,
            location: "energia"
        });

    };

    const pushSliderDatalayer = () => {

        window.dataLayer.push({
            event: "ga_event",
            event_name: "image_scroll_energy",
            event_category: " image scroll energy",
            event_action: `${activeFrame}:${subModules[activeFrame].title}`,
            event_label: undefined,
            event_value: undefined,
            title: `${activeFrame}:${subModules[activeFrame].title}`,
            location: "energia"
        });

        if (activeFrame === subModules.length - 1) {
            endSliderDatalayer();
        }
    };

    const pushOnClickDatalayer = () => {

        const currentLocation = window.location.pathname.split('/').length > 2 ? window.location.pathname.split('/')[2] : "";
        const linkUrl = window.location.origin + subModules[activeFrame - 1].link.link;

        window.dataLayer.push({
            event: "ga_event",
            event_name: "click_cta",
            event_category: "click cta",
            event_action: "click cta",
            event_value: undefined,
            event_label: `${activeFrame}:${subModules[activeFrame - 1].title}`,
            title: `${activeFrame}:${subModules[activeFrame - 1].title}`,
            link_text: subModules[activeFrame - 1].link.caption,
            link_url: linkUrl,
            location: currentLocation
        });

    };


    useSliderSwipe(headerSliderComponent, setActiveFrame, subModules.length, null, 99999, pushSliderDatalayer);
    useFade(headerSliderComponent);

    if (typeof window !== 'undefined') {
        return (
            <div className="module grid areas-actividad slow" data-active-frame={activeFrame} ref={headerSliderComponent}>
                <TagTitle style="slow" title={title} />
                {subModules.length > 1 && (
                    <>
                        <button
                            className="ghost-frame-button ghost-frame-button--left"
                            onMouseOver={prevHover}
                            onMouseOut={removeHover}
                            onClick={prevFrame}
                        >
                            Slider anterior
                        </button>
                        <button
                            className="ghost-frame-button ghost-frame-button--right"
                            onMouseOver={nextHover}
                            onMouseOut={removeHover}
                            onClick={nextFrame}
                        >
                            Slider siguiente
                        </button>
                    </>
                )}
                <div className="image-frames-container">
                    <div className="frames-container" style={imagesContainerWidth}>
                        {subModules.map(({ image, imageReplace }, idx) => {
                            const imageUrl = useWindowWidth() >= 767 ? image : image;

                            return (
                                <div className="image-frame data-point" data-frame-id={idx + 1} key={`frame-${idx}`}>
                                    <div className="img-container" style={{ backgroundImage: `url(${imageUrl ? imageUrl.url : ''})` }}></div>
                                </div>
                            );
                        })}
                        <span className="custom-anchor" id={anchor} />
                    </div>
                </div>
                <div className="slider-controls">
                    <button ref={prevButton} className="prev-button" onClick={prevFrame} >
                        <IconArrowRight className="arrow-prev" />
                    </button>
                    <button ref={nextButton} className="next-button" onClick={nextFrame} >
                        <IconArrowRight className="arrow-next" />
                    </button>
                </div>
                <div className="frames-container text-frames-container slow">
                    {subModules.map((frame, idx) => {
                        const { title, body, link } = frame;

                        return (
                            <div className="text-frame" ref={refList[idx]} data-frame-id={idx + 1} key={`frame-${idx}`}>
                                {link ?
                                    (<a href={link ? link.link : '#'} data-ref-id={idx + 1} onMouseOut={notHover} onMouseOver={onHover} target={link ? link.target : ''}>
                                        <h3 className="title title--m">{title}</h3>
                                    </a>) :
                                    (<h3 className="title title--m">{title}</h3>)
                                }
                                <p className="body body--m">{body}</p>
                                {link && <a onClick={pushOnClickDatalayer}><Link type="arrow" className="frame-cta" path={link.link} target={link.target}>
                                    {link.caption}
                                </Link></a>}
                            </div>
                        );
                    })}
                </div>

                {module_cta && <Link type="arrow" className="module_cta slow" path={module_cta.link} target={module_cta.target}>
                    {module_cta.caption}
                </Link>}
            </div>
        );
    }
    else {
        return (<></>);
    }
}

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default AreasActividad;

import React, { useRef, useState, useEffect } from 'react';

import { Link, Button } from 'React/components';

import './Header.scss';
import './NavSecondary.scss';
import './NavSubsection.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { useStickyHeader } from 'React/custom_hooks';

import { ReactComponent as User } from 'Icons/usuario-16.svg';
import { ReactComponent as HamburgerMenuIcon } from 'Icons/hamburgermenu.svg';
import { ReactComponent as HamburgerMenuIconTablet } from 'Icons/hamburgermenu-tablet.svg';
import { ReactComponent as Triangle } from 'Icons/triangleup.svg';
import { ReactComponent as SearchLense } from 'Icons/searchlense.svg';
import { ReactComponent as Plus } from 'Icons/plus-10.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconClose } from 'Icons/cross.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';

import IconsMap from './_iconsMap';

import { ReactComponent as Chevron_L } from 'Icons/chevron_down-18.svg';
import { getSiteLanguage } from '../../helpers';


const Header = ({ isVisible, stickyHeader, menu, contactLabel, contactLink, culture, searchLabel, labelPortada, labelCountriesMenu, logoLink, path, changeLanguageLabel, changeLanguageLabelSecondary, changeLanguageLink, secondaryLanguage, secondaryDialect }) => {

    const [searchTerm, setSearchTerm] = useState('');

    const searchInput = useRef(null);
    const searchInputTestStand = useRef(null);

    const swipeTargetHeader = React.createRef();
    const desktopNav = React.createRef();
    const mobileNav = React.createRef();
    const navDrawer = React.createRef();
    const countriesRef = React.createRef();
    const searchRef = React.createRef();

    const headerRef = useRef();

    const secondaryLanguageUrl = `/${secondaryLanguage}/`;

    var stockTicker = "https://ifbaspst.infobolsanet.com/ifblayout2/Layout.aspx?layout=MiniHome&client=Acciona2020";

    if (culture === "en") {
        stockTicker = stockTicker + "&lang=en";
    }

    function toggleMobileSubsection(event) {
        event.currentTarget.classList.toggle('active');

        document.querySelectorAll('.subsection__toggle').forEach(function (item) {
            if (item !== event.currentTarget) {
                item.classList.remove('active');
            }
        });
    }

    const onHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];

        navSubsections.forEach(function (item) {
            if (item.dataset.section === event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = true;
                item.classList.add('is-visible');
                navDrawer.current.dataset.sectionActive = item.dataset.section;
                navDrawer.current.dataset.isVisible = true;
                navDrawer.current.classList.add('is-visible');
            }

            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.classList.remove('is-visible');
            }

            if (event.currentTarget.dataset.children === 'no') {
                navDrawer.current.dataset.isVisible = false;
                navDrawer.current.classList.remove('is-visible');
            }
        });
    };

    const notHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];

        navSubsections.forEach(function (item) {
            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = false;
                item.classList.remove('is-visible');
            }
        });
    };

    const toggleSubsectionVisibility = () => {
        navDrawer.current.dataset.isVisible = false;
        navDrawer.current.classList.remove('is-visible');
    };

    const openMenu = () => {
        mobileNav.current.dataset.isVisible = true;
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const closeMenu = () => {
        mobileNav.current.dataset.isVisible = false;
        document.querySelector('.app--layout').dataset.canScroll = true;
    };

    const openCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = true;
    };

    const closeCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = false;
    };

    const openSearchOverlay = () => {

        var fullPath = window.location.href;
        var path = window.location.pathname;

        var objectData = {
            event: "ga_event",
            event_name: "menu",
            event_category: "menu",
            event_action: "top right",
            event_label: path,
            event_value: undefined,
            link_text: path,
            link_url: "#",
            location: "top right"
        }
        window.dataLayer.push(objectData);
        searchRef.current.dataset.isVisible = true;
    };

    const closeSearchOverlay = () => {
        searchRef.current.dataset.isVisible = false;
    };

    useEffect(() => {
        let navigatorLang = window.navigator.language.substring(0, 2);
        if (sessionStorage.getItem("RELOAD_FORCE_SECOND_LANG") == null && (navigatorLang === secondaryLanguage || navigatorLang === secondaryDialect)) {
            sessionStorage.setItem("RELOAD_FORCE_SECOND_LANG", navigatorLang);
            window.location.replace(changeLanguageLink);
            console.log("navigatorLang", navigatorLang);
            console.log("LOAD_SECOND_LANG", sessionStorage.getItem("RELOAD_FORCE_SECOND_LANG"));
        }
    });

    //Google Analytics - Agrupaciones de contenido 
    useEffect(() => {
        const url = window.location.pathname;
        const siteLanguage = getSiteLanguage();
        let page_type = "";

        if (url === '/' || url === '/no/') {
            page_type = 'home'
        }
        else if (url.includes("nuestro-proposito") ||
            url.includes("our-purpose") ||
            url.includes("vart-formal") ||
            url.includes("nosso-proposito")
        ) {
            page_type = "our-purpose";
        } else if (url.includes("soluciones") ||
            url.includes("solutions") ||
            url.includes("losninger") ||
            url.includes("solucoes")
        ) {
            page_type = "solutions";
        } else if (url.includes("proyectos") ||
            url.includes("projects") ||
            url.includes("prosjekter") ||
            url.includes("projetos")
        ) {
            page_type = "projects";
        } else if (url.includes("actualidad") ||
            url.includes("updates") ||
            url.includes("nyheter") ||
            url.includes("novidades")
        ) {
            page_type = "newsroom";
        } else if (url.includes("resultados") ||
            url.includes("results") ||
            url.includes("resultater")
        ) {
            page_type = "search";
        } else {
            page_type = "other";
        }

        dataLayer.push({
            language: siteLanguage,
            page_type: page_type
        });
    }, []);

    useEffect(() => {
        if (isVisible) searchInput.current.focus();
    }, [isVisible]);

    const handleInput = (e) => {
        setSearchTerm(e.target.value);

        const input = searchInput.current;
        const testStand = searchInputTestStand.current;

        // measure the length of the input text and update input width
        testStand.innerText = searchTerm;
        input.style.width = `calc(2em + ${testStand.getBoundingClientRect().width}px)`;
    };

    const pushDataLayerLanguage = (event) => {

        var objectData = {
            event: "ga_event",
            event_name: "language_change",
            event_category: "language change",
            event_action: culture === 'no' ? changeLanguageLabelSecondary + ': Englsh' :
                culture === 'es' ? 'español' :
                    culture === 'en' ? 'Englsh : ' + changeLanguageLabelSecondary :
                        changeLanguageLabelSecondary,
            event_label: undefined,
            event_value: undefined,
            option: culture === 'no' ? changeLanguageLabelSecondary + ': Englsh' :
                culture === 'es' ? 'español' :
                    culture === 'en' ? 'Englsh : ' + changeLanguageLabelSecondary :
                        changeLanguageLabelSecondary
        }
        console.log(objectData);
        // debugger;
        dataLayer.push(objectData)
    }
    const pushDataLayer = (event) => {
        var objectData = {
            event: "ga_event",
            event_name: "menu",
            event_category: "menu",
            event_action: event.currentTarget.attributes['data-menu-click-group'].value.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            event_label: event.currentTarget.attributes['data-menu-click-target'].value,
            event_value: undefined,
            link_text: event.currentTarget.attributes['data-menu-click-target'].value,
            link_url: window.location.origin + event.currentTarget.attributes['data-menu-click-target'].value,
            location: event.currentTarget.attributes['data-menu-click-group'].value.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
        }
        dataLayer.push(objectData)
    }
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        var searchText = event.target.search.value;

        window.location.href = typeof globalTranslations !== 'undefined' ? `/${culture === secondaryLanguage ? secondaryLanguage + '/' + globalTranslations.resultsLink :
            globalTranslations.resultsLink}?search = ${searchText}` : '';

    };

    useStickyHeader(headerRef, stickyHeader);

    return (
        <>
            <header ref={headerRef} className="page-header grid" id="header">
                <a className="logo-home-link" href={logoLink}>
                    <img className="logo" src={LogoFull} alt="Logo Acciona" />
                </a>

                <div className="header__row header__top header__country">
                    <div className="header-right">
                        <nav className="nav_secondary" aria-label="menú de navegación secundaria" role="navigation" >
                            {secondaryLanguage &&
                                <a
                                    onClick={pushDataLayerLanguage}
                                    href={culture === secondaryLanguage ? '/' : changeLanguageLink}
                                    data-menu-click-path={path}
                                    data-menu-click-group="top right"
                                    data-menu-click-target={changeLanguageLink} >{culture === secondaryLanguage ? changeLanguageLabel : changeLanguageLabelSecondary}</a>
                            }
                            <a onClick={pushDataLayer} className={`${path.includes(contactLink) ? 'is-active' : ''}`} href={contactLink} data-menu-click-path={path} data-menu-click-group="top right" data-menu-click-target={contactLink}>{contactLabel}</a>
                        </nav>
                    </div>
                    <HamburgerMenuIcon className="icon icon__hamburger icon__hamburger--mobile" onClick={openMenu} />

                    <button className="open-search-form" onClick={openSearchOverlay} aria-label="open search form">
                        <SearchLense />
                    </button>
                    <HamburgerMenuIconTablet className="icon icon__hamburger  icon__hamburger--tablet" onClick={openMenu} />
                </div>

                <div className="header__row header__bottom">
                    <nav
                        ref={desktopNav}
                        className="nav__main nav__main--desktop"
                        role="navigation"
                        aria-label="Navegación principal para escritorio"
                    >
                        <ul className="nav_links">
                            {menu.map((item, idx) => {
                                return (
                                    <a
                                        key={`nav_links__link-${idx}`}
                                        className={`link link-lvl-0${(culture === secondaryLanguage ? secondaryLanguageUrl + path.split("/")[2] + '/' : '/' + path.split("/")[1] + '/').includes(item.url) ? ' current' : ''}`}
                                        href={item.url}
                                        onMouseOut={notHover}
                                        onMouseOver={onHover}
                                        data-children={item.subItemsList.length > 0 ? "has-children" : "no"}
                                        data-section-id={culture === secondaryLanguage ? item.url.split("/")[2] : item.url.split("/")[1]}
                                        onClick={pushDataLayer}
                                        data-menu-click-path={path}
                                        data-menu-click-group="main"
                                        data-menu-click-target={item.url}
                                    >
                                        <span>{item.name}</span>
                                        {item.subItemsList.length > 0 &&
                                            <Chevron_L />
                                        }
                                    </a>
                                );
                            })}
                        </ul>
                    </nav>

                    <nav
                        className="nav__main nav__main--mobile"
                        ref={mobileNav}
                        data-is-visible="false"
                        role="navigation"
                        aria-label="Navegación principal para móvil"
                    >
                        <svg width="18" height="18" className="close-x" onClick={closeMenu}>
                            <path d="M10 2v6h6v1h-6v6H9V9H3V8h6V2h1z" fill="#1F140F" fillRule="evenodd"></path>
                        </svg>
                        <ul className="nav_links">

                            <button
                                data-section={culture === secondaryLanguage ? menu[0].url.split("/")[2] : menu[0].url.split("/")[1]}
                                type="link"
                                data-is-open="false"
                                onClick={toggleMobileSubsection}
                                className={`button taglabel button--md type__base subsection__toggle${path.includes(menu[0].url) ? ' active' : ''}`}
                            >
                                {menu[0].name}
                                <Chevron_L />
                            </button>
                            {menu[0].subItemsList.length > 0 &&
                                <div data-section={culture === secondaryLanguage ? menu[0].url.split("/")[2] : menu[0].url.split("/")[1]} className="nav_sub_section lvl-1" data-subsection-visible="false">
                                    {menu[0].subItemsList.map((subItem, idx) => {
                                        return (
                                            <a key={idx} className={`link link-lvl-1${path === subItem.url ? ' current' : ''}`} href={subItem.url}>
                                                <span>{subItem.name}</span>
                                            </a>
                                        );
                                    })}
                                    <a className={`link link-lvl-0${path === menu[0].url ? ' current' : ''}`} href={menu[0].url}>
                                        <span>{labelPortada} {menu[0].name}</span>
                                    </a>
                                </div>
                            }

                            <button
                                type="link"
                                data-section={culture === secondaryLanguage ? menu[1].url.split("/")[2] : menu[1].url.split("/")[1]}
                                data-is-open="false"
                                onClick={toggleMobileSubsection}
                                className={`button taglabel button--md type__base subsection__toggle${path.includes(menu[1].url) ? ' active' : ''}`}
                            >
                                {menu[1].name}
                                <Chevron_L />
                            </button>
                            <div data-section={culture === secondaryLanguage ? menu[1].url.split("/")[2] : menu[1].url.split("/")[1]} className="nav_sub_section lvl-1" data-subsection-visible="false">
                                {menu[1].subItemsList.map((item, idx) => {
                                    return (
                                        <a key={`soluciones__item-${idx}`} className={`link link-lvl-1${path.includes(item.url) ? ' current' : ''}`} href={item.url}>
                                            {IconsMap[item.icon]}
                                            <span>{item.name}</span>
                                        </a>
                                    );
                                })}
                                <a className={`link link-lvl-0${path === menu[1].url ? ' current' : ''}`} href={menu[1].url}>
                                    <span>{labelPortada} {menu[1].name}</span>
                                </a>
                            </div>

                            <a className={`link link-lvl-0${(culture === secondaryLanguage ? secondaryLanguageUrl + path.split("/")[2] + '/' : '/' + path.split("/")[1] + '/').includes(menu[2].url) ? ' current' : ''}`} href={menu[2].url}>
                                <span>{menu[2].name}</span>
                            </a>

                            <a className={`link link-lvl-0${(culture === secondaryLanguage ? secondaryLanguageUrl + path.split("/")[2] + '/' : '/' + path.split("/")[1] + '/').includes(menu[3].url) ? ' current' : ''}`} href={menu[3].url}>
                                <span>{menu[3].name}</span>
                            </a>

                            <button
                                type="link"
                                onClick={toggleMobileSubsection}
                                data-is-open="false"
                                data-section={culture === secondaryLanguage ? menu[4].url.split("/")[2] : menu[4].url.split("/")[1]}
                                className={`button taglabel button--md type__base subsection__toggle${path.includes(menu[4].url) ? ' active' : ''}`}
                            >
                                {menu[4].name}
                                <Chevron_L />
                            </button>

                            <div
                                data-section={culture === secondaryLanguage ? menu[4].url.split("/")[2] : menu[4].url.split("/")[1]}
                                className="nav_sub_section lvl-1"
                                data-subsection-visible="false"
                            >
                                {menu[4].subItemsList.map((item, idx) => {
                                    return (
                                        <a key={idx} className={`link link-lvl-1${path === item.url ? ' current' : ''}`} href={item.url}>
                                            <span>{item.name}</span>
                                        </a>
                                    );
                                })}
                                <a className={`link link-lvl-0${path === menu[4].url ? ' current' : ''}`} href={menu[4].url}>
                                    <span>{labelPortada} {menu[4].name}</span>
                                </a>
                            </div>
                        </ul>
                        <div className="nav__mobile-footer">
                            <div className="nav_secondary">
                                <a href={contactLink} className="title--xs">
                                    {contactLabel}
                                </a>
                                {/*DESCOMENTAR CUANDO TENGAMOS CAMBIO DE IDIOMA*/
                                    /*<a href={changeLanguageLink} className="title--xs">
                                    {changeLanguageLabel}
                                </a>*/
                                }
                            </div>
                        </div>
                    </nav>
                </div>
                <>
                    <div ref={navDrawer} className="nav nav__drawer" data-section-active="" data-is-visible="false">
                        <nav
                            data-is-visible="false"
                            data-order="1"
                            className="nav_sub_section"
                            data-section={culture === secondaryLanguage ? menu[0].url.split("/")[2] : menu[0].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[0].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">{menu[0].featuredPhrase}</p>
                                <a href={menu[0].url}
                                    title={menu[0].name}
                                    className="section-home-link"
                                    onClick={pushDataLayer}
                                    data-menu-click-path={path}
                                    data-menu-click-group="main"
                                    data-menu-click-target={menu[0].url}
                                >
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                <div className="subsection-box">
                                    {menu[0].subItemsList.map((item, idx) => {
                                        if (item.subItemsList.length === 0) {
                                            return (
                                                <a key={idx} className={`link${path === item.url ? ' current' : ''}`}
                                                    href={item.url}
                                                    onClick={pushDataLayer}
                                                    data-menu-click-path={path}
                                                    data-menu-click-group="main"
                                                    data-menu-click-target={item.url}>
                                                    <span>{item.name}</span>
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                                {menu[0].subItemsList.map((item, idx) => {
                                    if (item.subItemsList.length > 0) {
                                        return (
                                            <div className="subsection-box" key={idx}>
                                                <a className="link"
                                                    href={item.url}
                                                    onClick={pushDataLayer}
                                                    data-menu-click-path={path}
                                                    data-menu-click-group="main"
                                                    data-menu-click-target={item.url}>
                                                    {item.name}
                                                </a>
                                                {item.subItemsList.map((item, idx) => {
                                                    return (
                                                        <a className="link link-lvl-1"
                                                            key={idx}
                                                            href={item.url}
                                                            onClick={pushDataLayer}
                                                            data-menu-click-path={path}
                                                            data-menu-click-group="main"
                                                            data-menu-click-target={item.url}>
                                                            {item.name}
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </nav>
                        <nav
                            data-is-visible="false"
                            data-order="2"
                            className="nav_sub_section"
                            data-pp="ppp"
                            data-section={culture === secondaryLanguage ? menu[1].url.split("/")[2] : menu[1].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[1].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">
                                    {menu[1].featuredPhrase}
                                </p>
                                <a href={menu[1].url}
                                    title={menu[1].name}
                                    className="section-home-link"
                                    onClick={pushDataLayer}
                                    data-menu-click-path={path}
                                    data-menu-click-group="main"
                                    data-menu-click-target={menu[1].url}>
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                {menu[1].subItemsList.map((item, idx) => {
                                    return (
                                        <a className={`link${path.includes(item.url) ? ' current' : ''}`}
                                            href={item.url}
                                            key={idx}
                                            onClick={pushDataLayer}
                                            data-menu-click-path={path}
                                            data-menu-click-group="main"
                                            data-menu-click-target={item.url}>
                                            {IconsMap[item.icon]}
                                            <span>{item.name}</span>
                                        </a>
                                    );
                                })}
                            </div>
                        </nav>
                        <nav
                            data-is-visible="false"
                            data-order="4"
                            className="nav_sub_section"
                            data-section={culture === secondaryLanguage ? menu[3].url.split("/")[2] : menu[3].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[3].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">{menu[3].featuredPhrase}</p>
                                <a href={menu[3].url}
                                    title={menu[3].name}
                                    className="section-home-link"
                                    onClick={pushDataLayer}
                                    data-menu-click-path={path}
                                    data-menu-click-group="main"
                                    data-menu-click-target={menu[3].url}>
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                {menu[3].subItemsList.map((item, idx) => {
                                    if (item.subItemsList.length > 0) {
                                        return (
                                            <div className="subsection-box" key={idx}>
                                                <a className={`link${path === item.url ? ' current' : ''}`}
                                                    href={item.url}
                                                    onClick={pushDataLayer}
                                                    data-menu-click-path={path}
                                                    data-menu-click-group="main"
                                                    data-menu-click-target={item.url}>
                                                    <span>{item.name}</span>
                                                </a>
                                                {item.subItemsList.map((item, idx) => {
                                                    return (
                                                        <a className={`link link-lvl-1${path.includes(item.url) ? ' current' : ''}`}
                                                            href={item.url}
                                                            key={idx}
                                                            onClick={pushDataLayer}
                                                            data-menu-click-path={path}
                                                            data-menu-click-group="main"
                                                            data-menu-click-target={item.url}>
                                                            <span>{item.name}</span>
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }
                                })}
                                <div className="subsection-box">
                                    {menu[3].subItemsList.map((item, idx) => {
                                        if (item.subItemsList.length === 0) {
                                            return (
                                                <a className={`link${path.includes(item.url) ? ' current' : ''}`}
                                                    href={item.url}
                                                    key={idx}
                                                    onClick={pushDataLayer}
                                                    data-menu-click-path={path}
                                                    data-menu-click-group="main"
                                                    data-menu-click-target={item.url}>
                                                    <span>{item.name}</span>
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </nav>
                        <nav
                            data-is-visible="false"
                            data-order="6"
                            className="nav_sub_section"
                            data-section={culture === secondaryLanguage ? menu[4].url.split("/")[2] : menu[4].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[4].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">{menu[4].featuredPhrase}</p>
                                <a href={menu[4].url}
                                    title={menu[4].name}
                                    className="section-home-link"
                                    onClick={pushDataLayer}
                                    data-menu-click-path={path}
                                    data-menu-click-group="main"
                                    data-menu-click-target={menu[4].url}>
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                <div className="subsection-box">
                                    {menu[4].subItemsList.map((item, idx) => {
                                        if (item.subItemsList.length === 0) {
                                            return (
                                                <a className={`link${path.includes(item.url) ? ' current' : ''}`}
                                                    href={item.url}
                                                    key={idx}
                                                    onClick={pushDataLayer}
                                                    data-menu-click-path={path}
                                                    data-menu-click-group="main"
                                                    data-menu-click-target={item.url}>
                                                    <span>{item.name}</span>
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                                {menu[4].subItemsList.map((item, idx) => {
                                    if (item.subItemsList.length > 0) {
                                        return (
                                            <div className="subsection-box" key={idx}>
                                                <a className={`link${path.includes(item.url) ? ' current' : ''}`}
                                                    href={item.url}
                                                    onClick={pushDataLayer}
                                                    data-menu-click-path={path}
                                                    data-menu-click-group="main"
                                                    data-menu-click-target={item.url}>
                                                    <span>{item.name}</span>
                                                </a>
                                                {item.subItemsList.map((item, idx) => {
                                                    return (
                                                        <a className={`link link-lvl-1${path.includes(item.url) ? ' current' : ''}`}
                                                            href={item.url}
                                                            key={idx}
                                                            onClick={pushDataLayer}
                                                            data-menu-click-path={path}
                                                            data-menu-click-group="main"
                                                            data-menu-click-target={item.url}>
                                                            <span>{item.name}</span>
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </nav>
                    </div>
                    <div
                        className="drawer-overlay"
                        onClick={toggleSubsectionVisibility}
                        onMouseOver={toggleSubsectionVisibility}
                    ></div>
                </>
            </header>
            <>
                <div className={'bottom_drawer grid countries_overlay'} ref={countriesRef} data-is-visible="false">
                    <div className="header" ref={swipeTargetHeader}>
                        <h3 className="title title--xs">
                            {labelCountriesMenu}
                            <IconClose width="24" height="24" viewBox="0 0 16 16" onClick={closeCountriesOverlay} />
                        </h3>
                    </div>
                </div>

                <div data-is-visible="false" className="module grid search_overlay" ref={searchRef}>
                    <div className="header">
                        <h3 className="title title--xs">
                            <IconClose onClick={closeSearchOverlay} />
                        </h3>
                    </div>
                    <div className="body">
                        <form className="search-input-wrapper" onSubmit={handleSearchSubmit}>
                            <input onChange={handleInput} className="search-input" name="search" ref={searchInput} placeholder={searchLabel} />
                            <button className="search-submit" type="submit">
                                <SearchLense />
                            </button>
                        </form>
                        <div className="search-input-test-stand" ref={searchInputTestStand}></div>
                    </div>
                </div>
            </>
        </>
    );
};

export default Header;

import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useHeroParallax } from 'React/custom_hooks';
import ReactPlayer from 'react-player';

import { Link, Lightbox } from 'React/components';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as Play } from 'Icons/video_play-white.svg';

import './HeaderSlider.scss';

const HeaderSlider = (props) => {
    const [activeFrame, setActiveFrame] = useState(1);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [activeVideo, setActiveVideo] = useState(false);
    const [activeAutoplay, setActiveAutoplay] = useState(true);
    const [scrollTimeout, setScrollTimeout] = useState(null);

    const headerSliderComponent = React.createRef();
    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const frames = props.frames.map((frame, idx) => {
        frame.id = idx + 1;
        return frame;
    });

    const singleFrame = frames.length === 1;

    const handleDatalayer = (event) => {

        window.dataLayer.push({
            event: "ga_event",
            event_name: "slider_scroll",
            event_category: "slider scroll",
            event_action: event.target.value + " : " + event.currentTarget.attributes['data-title'].value.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            event_label: undefined,
            event_value: undefined,
            non_interaction: undefined,
            title: event.target.value + " : " + event.currentTarget.attributes['data-title'].value.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
        });

        if (parseInt(event.target.value) === frames.length) {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "slider_completed",
                event_category: "slider completed",
                event_action: undefined,
                event_label: undefined,
                event_value: undefined
            });
            console.log('slider completed')
        }

    };

    const handleSliderChange = (e) => {
        clearTimeout(scrollTimeout);
        const newValue = parseInt(e.target.value);
        setActiveFrame(newValue);
    };

    const sliderCompleted = () => {
        window.dataLayer.push({
            event: "ga_event",
            event_name: "slider_completed",
            event_category: "slider completed",
            event_action: undefined,
            event_label: undefined,
            event_value: undefined
        });
        console.log('slider completed')
        setActiveFrame(activeFrame + 1);
    }

    const getActiveFrameTitle = (activeFrame) => {
        return frames[activeFrame - 1].title;
    }

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
        stopAutoplay();
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
        stopAutoplay();
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
        playAutoplay();
    };

    const nextFrame = (event) => {
        clearTimeout(scrollTimeout);
        if (activeFrame < frames.length) {
            setActiveFrame(activeFrame + 1);

            var title = getActiveFrameTitle(activeFrame + 1);
            var frame = activeFrame + 1;

            window.dataLayer.push({
                event: "ga_event",
                event_name: "slider_scroll",
                event_category: "slider scroll",
                event_action: frame + " : " + title,
                event_label: undefined,
                event_value: undefined,
                non_interaction: undefined,
                title: frame + " : " + title
            });
            console.log('frame:', frame, title)

            if (activeFrame === (frames.length - 1)) {
                sliderCompleted();
            }

        };
    }

    const prevFrame = (event) => {
        clearTimeout(scrollTimeout);
        if (activeFrame > 1) {
            setActiveFrame(activeFrame - 1);
            var title = getActiveFrameTitle(activeFrame - 1);
            var frame = activeFrame - 1;

            window.dataLayer.push({
                event: "ga_event",
                event_name: "slider_scroll",
                event_category: "slider scroll",
                event_action: frame + " : " + title,
                event_label: undefined,
                event_value: undefined,
                non_interaction: undefined,
                title: frame + " : " + title
            });
            console.log("Frame", frame, title)
        }
    };


    useEffect(() => {
        if (activeAutoplay != false) {
            let scrTimeout;
            if (activeFrame < frames.length) {
                scrTimeout = setTimeout(() => {
                    setActiveFrame(activeFrame + 1);
                }, 6000);
            } else {
                scrTimeout = setTimeout(() => {
                    setActiveFrame(1);
                }, 6000);
            }
            setScrollTimeout(scrTimeout);
        } else {
            clearTimeout(scrollTimeout);
        }
    }, [activeFrame, activeAutoplay]);

    const toggleVideoState = (id) => {
        setVideosState((prevState) => {
            const newState = [...prevState];
            newState[id] = true;
            return newState;
        });
    };

    const startVideo = (videoId) => {
        const videoToPlay = frames[videoId].video;
        setActiveVideo('//www.youtube.com/watch?v=' + videoToPlay.videoId);
    };

    const stopAutoplay = () => {
        setActiveAutoplay(false);
    };

    const playAutoplay = () => {
        setActiveAutoplay(true);
    };

    const stopVideo = () => {
        setActiveVideo(null);
        setModalIsVisible(false);
    };

    useEffect(() => {
        if (activeVideo) {
            setModalIsVisible(true);
        }
    }, [activeVideo]);

    useSliderSwipe(headerSliderComponent, setActiveFrame, frames.length, setActiveAutoplay);
    useHeroParallax('.component__header-slider .backgrounds');

    return (
        <>
            <div
                className="module grid full-width component__header-slider"
                data-active-frame={activeFrame}
                data-single-frame={singleFrame}
                ref={headerSliderComponent}
                data-combined={props.combined}
            >
                <div className="backgrounds full-width">
                    {frames.map((frame, idx) => {
                        const { desktopImage, hasVeil, video, id } = frame;
                        return desktopImage ? (
                            <div
                                className={`img-container ${hasVeil ? '' : 'has-veil'}`}
                                key={`hsb-${id}`}
                                style={{ backgroundImage: `url(${desktopImage.url})` }}>
                            </div>
                        ) : (
                            <div
                                className={`video-container ${hasVeil ? '' : 'has-veil'}`}
                                key={`hsb-${id}`}
                                style={{ backgroundImage: `url(${video ? video.thumbnail : ''})` }}
                            ></div>
                        );
                    })}
                </div>
                <div className="content-blocks">
                    {frames.map((frame, idx) => {
                        const { title, body, cta, id, video } = frame;

                        const ctaMarkup = {
                            link: cta ? (
                                <a className="circled-arrow-link" href={cta.link} target={cta.target} data-title={title} data-frame={id} data-group-sliderclick="sliderClick">
                                    <ArrowRight width="70" height="70" viewBox="0 0 94 94" />
                                </a>
                                //<Link path={cta.link} className="circled-arrow-link" target={cta.target}>
                                //    <ArrowRight width="70" height="70" viewBox="0 0 94 94" />
                                //</Link>
                            ) : null,
                            play: (
                                <button data-frame-id={idx} onClick={() => startVideo(idx)} className="circled-button" data-title={title} data-group-sliderclick="sliderClick">
                                    <Play width="70" height="70" viewBox="0 0 94 94" />
                                </button>
                            ),
                        };

                        return (
                            <div className="block" data-content-block-id={id} key={`hscb-${id}`}>
                                <h2 className="title--xl">
                                    {title}
                                </h2>
                                <p className="body body--l">
                                    {body}
                                </p>
                                {video ? ctaMarkup['play'] : ctaMarkup['link']}
                            </div>
                        );
                    })}
                </div>
                <div className="controls">
                    <input type="range" min={1} max={frames.length} value={activeFrame} step={1} data-title={getActiveFrameTitle(activeFrame)} onChange={handleSliderChange} onClick={handleDatalayer} />
                    {frames.length > 1 && (
                        <>
                            <div className="slider-controls">
                                <button
                                    ref={prevButton}
                                    className="prev-button"
                                    onMouseOver={stopAutoplay}
                                    onMouseOut={playAutoplay}
                                    onClick={prevFrame}
                                    data-title={getActiveFrameTitle(activeFrame)}
                                    data-index={activeFrame}
                                >
                                    <IconArrowRightLong />
                                </button>
                                <button
                                    ref={nextButton}
                                    className="next-button"
                                    onMouseOver={stopAutoplay}
                                    onMouseOut={playAutoplay}
                                    onClick={nextFrame}
                                    data-title={getActiveFrameTitle(activeFrame)}
                                    data-index={activeFrame}
                                >
                                    <IconArrowRightLong />
                                </button>
                            </div>
                            <p className="frame-position-indicator">
                                <span className="active">{`0${activeFrame}`}</span>
                                <span className="last">{`0${frames.length}`}</span>
                            </p>
                        </>
                    )}
                </div>
                {frames.length > 1 && (
                    <>
                        <button
                            className="ghost-frame-button ghost-frame-button--left"
                            onMouseOver={prevHover}
                            onMouseOut={removeHover}
                            onClick={prevFrame}
                            data-title={getActiveFrameTitle(activeFrame)}
                            data-index={activeFrame}
                        ></button>
                        <button
                            className="ghost-frame-button ghost-frame-button--right"
                            onMouseOver={nextHover}
                            onMouseOut={removeHover}
                            onClick={nextFrame}
                            data-title={getActiveFrameTitle(activeFrame)}
                            data-index={activeFrame}
                        ></button>
                    </>
                )}
            </div>

            {modalIsVisible && (
                <Lightbox isVisible={modalIsVisible} closeMethod={stopVideo}>
                    <ReactPlayer className="youtubePlayer-iframe" url={activeVideo} width="100%" height="auto" playing={true} controls={true} />
                </Lightbox>
            )}
        </>
    );
};

export default HeaderSlider;

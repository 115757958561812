import React from 'react';

import 'Styles/_texts.scss';
import './style.scss';

const WysiwygNoticia = ({ media, body }) => {

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    }


    return (
        <div className="module module-wysiwyg">
            <div className="grid">
                {media && <div className="image-featured"><div className="image-content"><img src={media.url} alt={media.alt ? media.alt : ''} title={media.title ? media.title : ''} /></div></div>}
                <div className="text-container" dangerouslySetInnerHTML={{ __html: body }}></div>
            </div>
        </div>
    )
};

export default WysiwygNoticia;

import React, { useState, useEffect } from 'react';
import { BuscadorFiltrosProyectos, ResultadosProyectos, Paginator } from 'React/components';
import { setCookie, getCookie, getSolutionsArray } from 'React/helpers';
import { document } from 'window-or-global';

const ProjectsList = ({ isList, culture, whatTypeProjectLabel }) => {
    const [allProjectListState, setAllProjectListState] = useState([]);
    const [projectListState, setProjectListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [actualPagesList, setActualPagesList] = useState([]);

    const [activeSolutionState, setActiveSolutionState] = useState('');
    const [activeAreaState, setActiveAreaState] = useState('');
    const [activeCountryState, setActiveCountryState] = useState('');
    const [typeFilterState, setTypeFilterState] = useState([]);
    const [stateFilterState, setStateFilterState] = useState([]);
    const [ownerFilterState, setOwnerFilterState] = useState([]);
    const [waterFilterState, setWaterFilterState] = useState([]);
    const [activityAreasCombo, setActivityAreasCombo] = useState([]);
    const [countriesCombo, setCountriesCombo] = useState([]);

    const itemsPerPage = 6;

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const solutionsCombo = getSolutionsArray(culture);

    const setActiveSolution = (event) => {
        setActiveSolutionState(event.target.value);
        getCountries(event.target.value);
        setActiveSolutionState(event.target.value);
        setActiveAreaState('');
        setActiveCountryState('');
        event.target.value !== '' ? getActivityAreas(event.target.value) : null;
        setTypeFilterState([]);
        setStateFilterState([]);
        setOwnerFilterState([]);
        setWaterFilterState([]);
        document.getElementById("innovacion").checked = false;
        document.getElementById("sostenibilidad").checked = false;
        document.getElementById("ejecutado").checked = false;
        document.getElementById("instalada").checked = false;
        document.getElementById("en-construccion").checked = false;
        document.getElementById("en-construccion2").checked = false;
        document.getElementById("en-propiedad").checked = false;
        document.getElementById("cliente").checked = false;
    };

    const setActiveArea = (event) => {
        getCountries(activeSolutionState, event.target.value);
        setActiveAreaState(event.target.value);
    };
    const setActiveCountry = (event) => {
        setActiveCountryState(event.target.value);
    };

    function removeItemAll(arr, value) {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    }

    const getCountries = (solution, activeArea) => {
        var projectTypeFilter = typeFilterState.join(",");
        var stateFilter = stateFilterState.join(",");
        var titularityFilter = ownerFilterState.join(",");
        var url = `/api/CmsController/GetProjectCountryList?language=${culture}&solutionFilter=${solution ? solution : ''}&activityAreaFilter=${activeArea ? activeArea : ''}&projectTypeFilter=${projectTypeFilter ? projectTypeFilter : ''}&stateFilter=${stateFilter ? stateFilter : ''}&titularityFilter=${titularityFilter ? titularityFilter : ''}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    var response = JSON.parse(xhrPages.responseText);

                    setCountriesCombo(response);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);
    };

    const getActivityAreas = (solution) => {
        var url = `/api/CmsController/GetProjectAreasList?language=${culture}&solution=${solution ? solution : ''}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    var response = JSON.parse(xhrPages.responseText);

                    setActivityAreasCombo(response);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);
    };

    const searchProjects = () => {
        if (!isList) {
            var urlState;
            var urlOwner;
            var states = [];
            var owners = [];
            if (activeSolutionState && stateFilterState) {
                if (activeSolutionState === 'Energia') {
                    for (var i = 0; i < stateFilterState.length; i++) {
                        if (stateFilterState[i] === '1941') states.push('Instalada');
                        else if (stateFilterState[i] === '1942') states.push('En construccion');
                        else if (stateFilterState[i] === '2989') states.push('En operacion');
                    }
                    urlState = states.join(",");
                }
                else {
                    for (var i = 0; i < stateFilterState.length; i++) {
                        if (stateFilterState[i] === '1941') states.push('Ejecutada');
                        else if (stateFilterState[i] === '1942') states.push('En construccion');
                        else if (stateFilterState[i] === '2989') states.push('En operacion');
                    }
                    urlState = states.join(",");
                }

            }
            if (ownerFilterState) {
                for (var i = 0; i < ownerFilterState.length; i++) {
                    if (ownerFilterState[i] === '1940') owners.push('En propiedad');
                    else if (ownerFilterState[i] === '1939') owners.push('Cliente');
                }
                urlOwner = owners.join(",");
            }

            var valuesFilters = {
                solution: activeSolutionState,
                area: activeAreaState,
                country: activeCountryState,
                type: typeFilterState,
                state: states,
                owner: owners
            };

            GetSetCookie(valuesFilters);

            pushDataLayer(valuesFilters.solution, valuesFilters.area, valuesFilters.country, valuesFilters.type, valuesFilters.state, valuesFilters.owner);

            var projectsUrl = typeof globalTranslations !== 'undefined' ? globalTranslations.projectsResultUrl : '';
            var url = `${projectsUrl}${activeSolutionState ? 'solution=' + activeSolutionState : ''}${activeAreaState ? '&area=' + activeAreaState : ''}${activeCountryState ? '&country=' + activeCountryState : ''}${typeFilterState.length > 0 ? '&type=' + typeFilterState.join(',') : ''}${urlState ? '&state=' + urlState : ''}${urlOwner ? '&owner=' + urlOwner : ''}`;
            window.location.href = url;
        }
        else {
            var states = [];
            var owners = [];
            if (activeSolutionState && stateFilterState) {
                if (activeSolutionState === 'Energia') {
                    for (var i = 0; i < stateFilterState.length; i++) {
                        if (stateFilterState[i] === '1941') states.push('Instalada');
                        else if (stateFilterState[i] === '1942') states.push('En construccion');
                        else if (stateFilterState[i] === '2989') states.push('En operacion');
                    }
                    urlState = states.join(",");
                }
                else {
                    for (var i = 0; i < stateFilterState.length; i++) {
                        if (stateFilterState[i] === '1941') states.push('Ejecutada');
                        else if (stateFilterState[i] === '1942') states.push('En construccion');
                        else if (stateFilterState[i] === '2989') states.push('En operacion');
                    }
                    urlState = states.join(",");
                }

            }
            if (ownerFilterState) {
                for (var i = 0; i < ownerFilterState.length; i++) {
                    if (ownerFilterState[i] === '1940') owners.push('En propiedad');
                    else if (ownerFilterState[i] === '1939') owners.push('Cliente');
                }
                urlOwner = owners.join(",");
            }
            var valuesFilters = {
                solution: activeSolutionState,
                area: activeAreaState,
                country: activeCountryState,
                type: typeFilterState,
                state: states,
                owner: owners
            };

            GetSetCookie(valuesFilters);
            setActualPageState(1);

            pushDataLayer(valuesFilters.solution, valuesFilters.area, valuesFilters.country, valuesFilters.type, valuesFilters.state, valuesFilters.owner);

            getProjectsList(culture, 1, activeSolutionState, activeAreaState, activeCountryState, typeFilterState.join(","), stateFilterState.join(","), ownerFilterState.join(","), waterFilterState.join(","));
        }
    };

    const setFilter = (option) => {

        var filterList = [];

        if (option === "Innovación" || option === "Innovation" || option === "Innovacion" || option === "Inovação") {
            filterList = typeFilterState;
            if (filterList.indexOf("Innovacion") > -1) filterList = removeItemAll(filterList, "Innovacion");
            else filterList.push("Innovacion");
            setTypeFilterState(filterList);
        }
        else if (option === "Sostenibilidad" || option === "Sustainability" || option === "Sustentabilidade") {
            filterList = typeFilterState;
            if (filterList.indexOf("Sostenibilidad") > -1) filterList = removeItemAll(filterList, "Sostenibilidad");
            else filterList.push("Sostenibilidad");
            setTypeFilterState(filterList);
        }
        else if (option === "Instalada" || option === "Installed" || option === "Instalado" || option === "Ejecutado" || option === "Executed" || option === "Executado") {
            filterList = stateFilterState;
            if (filterList.indexOf("1941") > -1) filterList = removeItemAll(filterList, "1941");
            else filterList.push("1941");
            setStateFilterState(filterList);
        }
        else if (option === "En construcción" || option === "In construction" || option === "En construccion" || option === "Em construção") {
            filterList = stateFilterState;
            if (filterList.indexOf("1942") > -1) filterList = removeItemAll(filterList, "1942");
            else filterList.push("1942");
            setStateFilterState(filterList);
        }
        else if (option === "En operación" || option === "In operation" || option === "En operacion") {
            filterList = stateFilterState;
            if (filterList.indexOf("2989") > -1) filterList = removeItemAll(filterList, "2989");
            else filterList.push("2989");
            setStateFilterState(filterList);
        }
        else if (option === "En propiedad" || option === "Owned" || option === "Na propriedade") {
            filterList = ownerFilterState;
            if (filterList.indexOf("1940") > -1) filterList = removeItemAll(filterList, "1940");
            else filterList.push("1940");
            setOwnerFilterState(filterList);
        }
        else if (option === "Cliente" || option === "Client") {
            filterList = ownerFilterState;
            if (filterList.indexOf("1939") > -1) filterList = removeItemAll(filterList, "1939");
            else filterList.push("1939");
            setOwnerFilterState(filterList);
        }
    };

    const recalculatePages = (page) => {
        const sidePages = 7;

        var nextList = [];
        var befList = [];
        nextList = pagesListState.slice(page - 1, page + sidePages);
        befList = pagesListState.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);

        if (page == 1) {
            setActualPagesList(nextList);
        }
        else if (page == pagesListState[pagesListState.length - 1].num) {
            setActualPagesList(befList);
        }
        else if (page == actualPagesList[actualPagesList.length - 1].num) {
            setActualPagesList(nextList);
        }
        else if (page == pagesListState[pagesListState.length - 1].num || page == actualPagesList[0].num) {
            setActualPagesList(befList);
        }
        else if (page < actualPagesList[0].num) {
            befList = pagesListState.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
            setActualPagesList(befList);
        }
        else if (page > pagesListState[pagesListState.length - 1].num) {
            nextList = pagesListState.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
            setActualPagesList(nextList);
        }
    };

    const getProjectsList = (culture, page, solutionFilter, activityFilter, countryFilter, projectTypeFilter, stateFilter, titularityFilter, waterFilter) => {
        if (isList) {
            var solution = solutionFilter ? solutionFilter : '';
            var activity = activityFilter ? activityFilter : '';
            var country = countryFilter ? countryFilter : '';
            var proyectType = projectTypeFilter ? projectTypeFilter : '';
            var state = stateFilter ? stateFilter : '';
            var titularity = titularityFilter ? titularityFilter : '';
            var water = waterFilter ? waterFilter : '';
            var url = `/api/CmsController/GetProjectList?language=${culture}&pageIndex=${page}&solutionFilter=${solutionFilter ? solutionFilter : ''}&activityAreaFilter=${activityFilter ? activityFilter : ''}&countryFilter=${countryFilter ? countryFilter : ''}&projectTypeFilter=${projectTypeFilter ? projectTypeFilter : ''}&stateFilter=${stateFilter ? stateFilter : ''}&titularityFilter=${titularityFilter ? titularityFilter : ''}&waterFilter=${waterFilter ? waterFilter : ''}`;

            //if (solution != "") {
            //    window.dataLayer.push({
            //        event: "ga_event",
            //        event_name: "filter_submit",
            //        event_category: "filter submit",
            //        event_action: "project map",
            //        event_label: "solucion : " + solution.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            //        event_value: undefined,
            //        category: "project map",
            //        option: "solucion : " + solution.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            //    });
            //}

            //if (activity != "") {
            //    window.dataLayer.push({
            //        event: "ga_event",
            //        event_name: "filter_submit",
            //        event_category: "filter submit",
            //        event_action: "project map",
            //        event_label: "area : " + activity.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            //        event_value: undefined,
            //        category: "project map",
            //        option: "area : " + activity.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            //    });
            //}
            //if (country != "") {
            //    window.dataLayer.push({
            //        event: "ga_event",
            //        event_name: "filter_submit",
            //        event_category: "filter submit",
            //        event_action: "project map",
            //        event_label: "pais : " + country.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            //        event_value: undefined,
            //        category: "project map",
            //        option: "pais : " + country.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            //    });
            //}
            //if (proyectType != "") {
            //    window.dataLayer.push({
            //        event: "ga_event",
            //        event_name: "filter_submit",
            //        event_category: "filter submit",
            //        event_action: "project map",
            //        event_label: "tipo : " + proyectType.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            //        event_value: undefined,
            //        category: "project map",
            //        option: "tipo : " + proyectType.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            //    });
            //}
            //if (state != "") {
            //    window.dataLayer.push({
            //        event: "ga_event",
            //        event_name: "filter_submit",
            //        event_category: "filter submit",
            //        event_action: "project map",
            //        event_label: "estado : " + state.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            //        event_value: undefined,
            //        category: "project map",
            //        option: "estado : " + state.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            //    });
            //}
            //if (titularity != "") {
            //    window.dataLayer.push({
            //        event: "ga_event",
            //        event_name: "filter_submit",
            //        event_category: "filter submit",
            //        event_action: "project map",
            //        event_label: "titularidad : " + titularity.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            //        event_value: undefined,
            //        category: "project map",
            //        option: "titularidad: " + titularity.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            //    });
            //}
            //if (water != "") {
            //    window.dataLayer.push({
            //        event: "ga_event",
            //        event_name: "filter_submit",
            //        event_category: "filter submit",
            //        event_action: "project map",
            //        event_label: "agua : " + water.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            //        event_value: undefined,
            //        category: "project map",
            //        option: "agua: " + water.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            //    });
            //}

            const xhrPages = new XMLHttpRequest();
            xhrPages.open('get', url, true);
            xhrPages.onload = function (e) {
                if (xhrPages.readyState === 4) {
                    if (xhrPages.status === 200) {
                        var responsePaginated = JSON.parse(xhrPages.responseText);

                        var pagesList = [];
                        var listSize = Math.ceil(responsePaginated.projectList.length / itemsPerPage);

                        for (var i = 0; i !== listSize; i++) {
                            var p = { num: i + 1 };
                            pagesList.push(p);
                        }

                        const sidePages = 7;

                        var nextList = [];
                        var befList = [];
                        nextList = pagesList.slice(page - 1, page + sidePages);
                        befList = pagesList.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);

                        if (page == 1) {
                            setActualPagesList(nextList);
                        }
                        else if (page == pagesList[pagesList.length - 1].num) {
                            setActualPagesList(befList);
                        }
                        else if (page == actualPagesList[actualPagesList.length - 1].num) {
                            setActualPagesList(nextList);
                        }
                        else if (page == pagesList[pagesList.length - 1].num || page == actualPagesList[0].num) {
                            setActualPagesList(befList);
                        }
                        else if (page < actualPagesList[0].num) {
                            befList = pagesList.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
                            setActualPagesList(befList);
                        }
                        else if (page > pagesList[pagesList.length - 1].num) {
                            nextList = pagesList.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
                            setActualPagesList(nextList);
                        }

                        var paginatedList = paginate(responsePaginated.projectList, itemsPerPage, actualPageState);

                        if (paginatedList.length < itemsPerPage) {
                            var ej1 = {
                                title: ""
                            };

                            var size = itemsPerPage - paginatedList.length;
                            for (var i = 0; i < size; i++) {
                                paginatedList.push(ej1);
                            }
                        }

                        setAllProjectListState(responsePaginated.projectList);
                        setProjectListState(paginatedList);
                        setPagesListState(pagesList);

                    } else {
                        console.error(xhrPages.statusText);
                    }
                }
            }.bind(this);

            xhrPages.send(null);
        }
    };

    const changePage = (page) => {
        setActualPageState(page);

        var paginatedList = paginate(allProjectListState, itemsPerPage, page);

        if (paginatedList.length < itemsPerPage) {
            var ej1 = {
                title: ""
            };
            var size = itemsPerPage - paginatedList.length;
            for (var j = 0; j < size; j++) {
                paginatedList.push(ej1);
            }
        }

        setProjectListState(paginatedList);
        recalculatePages(page);

        document.getElementById("scrollTo").scrollIntoView();
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);

            var paginatedList = paginate(allProjectListState, itemsPerPage, actualPageState + 1);

            if (paginatedList.length < itemsPerPage) {
                var ej1 = {
                    title: ""
                };
                var size = itemsPerPage - paginatedList.length;
                for (var j = 0; j < size; j++) {
                    paginatedList.push(ej1);
                }
            }

            setProjectListState(paginatedList);
            recalculatePages(actualPageState + 1);

            document.getElementById("scrollTo").scrollIntoView();
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);

            var paginatedList = paginate(allProjectListState, itemsPerPage, actualPageState - 1);

            if (paginatedList.length < itemsPerPage) {
                var ej1 = {
                    title: ""
                };
                var size = itemsPerPage - paginatedList.length;
                for (var j = 0; j < size; j++) {
                    paginatedList.push(ej1);
                }
            }

            setProjectListState(paginatedList);
            recalculatePages(actualPageState - 1);

            document.getElementById("scrollTo").scrollIntoView();
        }
    };

    function findGetParameter(parameterName) {
        var result = '',
            tmp = [];
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }

    const GetSetCookie = (valuesFilters) => {
        if (valuesFilters != null) {
            setCookie('filtersValues', valuesFilters, 10);
        }
    };

    const pushDataLayer = (solution, area, country, type, state, owner) => {


        if (solution != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "project map",
                event_label: "solucion : " + solution.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "projects",
                option: "solucion : " + solution.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (area != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "project map",
                event_label: "area : " + area.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "projects",
                option: "area : " + area.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (country != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "project map",
                event_label: "pais : " + country.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "projects",
                option: "pais : " + country.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (type.toString() != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "project map",
                event_label: "tipo : " + type.toString().toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "projects",
                option: "tipo : " + type.toString().toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (state.toString() != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "project map",
                event_label: "estado : " + state.toString().toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "projects",
                option: "estado : " + state.toString().toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (owner.toString() != "") {

            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "project map",
                event_label: "titularidad : " + owner.toString().toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "projects",
                option: "titularidad: " + owner.toString().toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        //if (water != "") {
        //    window.dataLayer.push({
        //        event: "ga_event",
        //        event_name: "filter_submit",
        //        event_category: "filter submit",
        //        event_action: "project map",
        //        event_label: "agua : " + water.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
        //        event_value: undefined,
        //        category: "project map",
        //        option: "agua: " + water.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
        //    });
        //}
    }


    useEffect(() => {
        if (isList) {
            var solution = findGetParameter("solution");
            var area = findGetParameter("area");
            var country = findGetParameter("country");
            var type = findGetParameter("type");
            var state = findGetParameter("state");
            var owner = findGetParameter("owner");

            //Cookie
            var cookieValues = getCookie('filtersValues');
            if (cookieValues != null || cookieValues != undefined) {
                var cookieToObj = JSON.parse(cookieValues);

                solution = cookieToObj.solution;
                area = cookieToObj.area;
                country = cookieToObj.country;
                type = cookieToObj.type.join(",");
                state = cookieToObj.state.join(",");
                owner = cookieToObj.owner.join(",");
            };

            var inSolutionCombo = solutionsCombo.find((item) => item.value === solution);

            inSolutionCombo ? setActiveSolution({ target: { value: solution } }) : null;
            area ? setActiveAreaState(area) : null;
            country ? setActiveCountryState(country.toUpperCase()) : null;

            if (type && type.length > 0) {
                var typeArray = type.split(",");
                for (var i = 0; i < typeArray.length; i++) {
                    setFilter(typeArray[i]);
                }
                if (type.includes("Innovacion")) document.getElementById("innovacion") ? document.getElementById("innovacion").checked = true : null;
                else document.getElementById("innovacion") ? document.getElementById("innovacion").checked = false : null;

                if (type.includes("Sostenibilidad")) document.getElementById("sostenibilidad") ? document.getElementById("sostenibilidad").checked = true : null;
                else document.getElementById("sostenibilidad") ? document.getElementById("sostenibilidad").checked = false : null;
            }

            if (state && state.length > 0) {
                var codedState = [];
                var stateArray = state.split(",");

                for (var i = 0; i < stateArray.length; i++) {
                    if (stateArray[i] === "Instalada" || stateArray[i] === "Ejecutada") {
                        codedState.push("1941");
                        document.getElementById("instalada") ? document.getElementById("instalada").checked = true : null;
                        document.getElementById("ejecutada") ? document.getElementById("ejecutada").checked = true : null;
                    }
                    else if (stateArray[i] === "En operacion") {
                        codedState.push("2989");
                        document.getElementById("en-operacion") ? document.getElementById("en-operacion").checked = true : null;
                        document.getElementById("en-operacion2") ? document.getElementById("en-operacion2").checked = true : null;
                    }
                    else {
                        codedState.push("1942");
                        document.getElementById("en-construccion") ? document.getElementById("en-construccion").checked = true : null;
                        document.getElementById("en-construccion2") ? document.getElementById("en-construccion2").checked = true : null;
                    }
                }
                setStateFilterState(codedState);
                state = codedState.join(",");
            }
            else {
                document.getElementById("instalada") ? document.getElementById("instalada").checked = false : null;
                document.getElementById("ejecutada") ? document.getElementById("ejecutada").checked = false : null;
                document.getElementById("en-construccion") ? document.getElementById("en-construccion").checked = false : null;
                document.getElementById("en-construccion2") ? document.getElementById("en-construccion2").checked = false : null;
                document.getElementById("en-operacion") ? document.getElementById("en-operacion").checked = false : null;
                document.getElementById("en-operacion2") ? document.getElementById("en-operacion2").checked = false : null;
            }

            if (owner && owner.length > 0) {
                var codedOwner = [];
                var ownerArray = owner.split(",");

                for (var i = 0; i < ownerArray.length; i++) {
                    if (ownerArray[i] === "En propiedad") {
                        codedOwner.push("1940");
                        document.getElementById("en-propiedad") ? document.getElementById("en-propiedad").checked = true : null;
                    }
                    else {
                        codedOwner.push("1939");
                        document.getElementById("cliente") ? document.getElementById("cliente").checked = true : null;
                    }
                }
                setOwnerFilterState(codedOwner);
                owner = codedOwner.join(",");
            }
            else {
                document.getElementById("en-propiedad") ? document.getElementById("en-propiedad").checked = false : null;
                document.getElementById("cliente") ? document.getElementById("cliente").checked = false : null;
            }

            getProjectsList(culture, 1, solution, area, country, type, state, owner);
            getCountries(solution, area);
            //else if (country) {
            //    getCountries("", "");
            //    setActiveCountryState(country);
            //    getProjectsList(culture, 1, "", "", country, type);
            //}
            //else if (type) {
            //    getCountries("", "");
            //    getProjectsList(culture, 1, "", "", country, type);
            //}
            //else {

            //    getProjectsList(culture, 1, "", "");
            //}            

        }
        else {
            getCountries("", "");
            pushDataLayer("", "", "", "", "", "");
        }
    }, [setPagesListState]);

    return (
        <div className='module'>
            {!isList &&
                <BuscadorFiltrosProyectos type='proyectos' searchFilter={setFilter} culture={culture} whatTypeProjectLabel={whatTypeProjectLabel}
                    solutionsCombo={solutionsCombo} areasCombo={activityAreasCombo} countriesCombo={countriesCombo}
                    setActiveSolution={setActiveSolution} activeSolution={activeSolutionState} setActiveArea={setActiveArea} activeArea={activeAreaState}
                    setActiveCountry={setActiveCountry} activeCountry={activeCountryState} searchProjects={searchProjects} hideFilters />
            }
            {isList &&
                <BuscadorFiltrosProyectos type='proyectos' searchFilter={setFilter} culture={culture} whatTypeProjectLabel={whatTypeProjectLabel}
                    solutionsCombo={solutionsCombo} areasCombo={activityAreasCombo} countriesCombo={countriesCombo}
                    setActiveSolution={setActiveSolution} activeSolution={activeSolutionState} setActiveArea={setActiveArea} activeArea={activeAreaState}
                    setActiveCountry={setActiveCountry} activeCountry={activeCountryState} searchProjects={searchProjects} />
            }
            <span className="custom-anchor-small" id="scrollTo" />
            <ResultadosProyectos frames={projectListState} />
            {pagesListState.length > 1 &&
                <Paginator pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} actualPagesList={actualPagesList} />
            }
        </div>
    );
}

export default ProjectsList;
import React, { useState, useEffect } from 'react';
import { IconsMap } from '../Icons/Icons';
import { TagTitle, Link, Wysiwyg } from 'React/components';
import { removeAccents } from '../../helpers';
import useTranslate from '../../custom_hooks/useTranslate';


const SolutionAreasWithText = (props) => {

    const { tagTitle, cta, headers, _contentPath, text, areaSelected, titleText, } = props;
    const [isSolutionDetail, setIsSolutionDetail] = useState(false);
    const subModules = props.subModules;

    useEffect(() => {
        const pathname = window.location.pathname;

        if (pathname.includes('es/soluciones/energia/') || pathname.includes('/solutions/energy/')) {
            setIsSolutionDetail(true);
        } else {
            setIsSolutionDetail(false);
        }

    }, [isSolutionDetail]);

    const solutionLink = _contentPath.startsWith('/es/')
        ? '/es/soluciones/'
        : '/solutions/';


    const filteredData = headers
        .map((header, idx) => ({
            header,
            subModules: subModules.map((row) => row.subModules[idx]),
            linkToSolution: `${solutionLink}${removeAccents(header)}/`,
        }))
        .filter((item) => areaSelected.includes(item.header));

    return (
        <div className={`module table-solution-text ${isSolutionDetail ? 'optional-margin' : ''}`}>
            <div className="table-solution-text-title grid">
                {tagTitle && <TagTitle title={tagTitle} />}

            </div>
            <div className="container">
                <div className="wysiwyg-text">{text && <Wysiwyg html={text} />}</div>

                <div className="activity-areas">
                    {filteredData.map((item, idx) => (
                        <div className="activity-item" key={idx}>
                            <div className="title-activity">
                                <span className="icon icon--red">
                                    {IconsMap[useTranslate(item.header.replace(/\s/g, ''))]}
                                </span>

                                <h1 className="title">{titleText}</h1>
                            </div>

                            <ul key={idx} className="link area-list">
                                {item.subModules.map((areaName, areaIdx) => {
                                    const { _documentType, text, className } = areaName;

                                    const textOneLine = text.replace('<br />', ' ');
                                    return (
                                        <div
                                            key={areaIdx}
                                            className="--left"
                                            dangerouslySetInnerHTML={{ __html: textOneLine }}
                                        ></div>
                                    );
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            {cta && (
                <div className="table-solution-text-title grid">
                    <Link label={cta.caption} path={cta.link} type={cta.type} />
                </div>
            )}
        </div>
    );
};

export default SolutionAreasWithText;

import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, TrabajaConNosotrosSimple, Paginator } from 'React/components';
import { findGetParameter, setCookie, getCookie } from 'React/helpers';

const WorkDay = ({
    culture,
    labelAllcountries,
    labelAllsolutions,
    labelAllareas,
    labelEligeOpcion,
    labelCountry,
    labelSolution,
    labelArea,
    labelVacancieType,
    labelProfesional,
    labelGraduates,
    labelBecas,
    linkOfferDetail,
    labelOcultarFiltros,
    labelMostrarFiltros,
    labelDe
}

) => {

    const [areasState, setAreasState] = useState([]);
    const [divisionsState, setDivisionsState] = useState([]);
    const [countriesState, setCountriesState] = useState([]);
    const [areasSelectedState, setAreasSelectedState] = useState('');
    const [divisionsSelectedState, setDivisionsSelectedState] = useState('');
    const [countriesSelectedState, setCountriesSelectedState] = useState('');
    const [jobTypeSelectedState, setJobTypeSelectedState] = useState('');
    const [listOffersState, setlistOffersState] = useState([]);
    const [actualPagesList, setActualPagesList] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);


    var country = '';
    var area = '';
    var division = '';
    var jobtype = '';

    const getComboData = () => {

        try {
            //ToDo: mirar como hacerlo async
            if (areasState.length === 0 && divisionsState.length === 0 && countriesState.length === 0) {
                Promise.all([
                    fetch('/api/CmsController/GetAreas?language=' + culture, {
                        method: 'GET'
                    }).then(data => data.json()),
                    fetch('/api/CmsController/GetDivisions?language=' + culture, {
                        method: 'GET'
                    }).then(data => data.json()),
                    fetch('/api/CmsController/GetCountries?language=' + culture, {
                        method: 'GET'
                    }).then(data => data.json())

                ]).then((data) => {
                    setAreasState(data[0]);
                    setDivisionsState(data[1]);
                    setCountriesState(data[2]);
                });
            }
        }

        catch (err) {
            console.log(err);
        }
    };


    const getOffers = (page, urlDivision, urlCountry, urlArea, urlJobType) => {

        var division = urlDivision ? urlDivision : divisionsSelectedState;
        division = division === 'Energia' ? 'Energía' : division;
        var country = urlCountry ? urlCountry : countriesSelectedState;
        var area = urlArea ? urlArea : areasSelectedState;
        var jobType = urlJobType ? urlJobType : jobTypeSelectedState;


        if (country != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "job offers",
                event_label: "paises : " + country.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "job offers",
                option: "paises : " + country.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (division != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "job offers",
                event_label: "negocios : " + division.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "job offers",
                option: "negocios : " + division.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (area != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "job offers",
                event_label: "areas : " + area.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "job offers",
                option: "areas : " + area.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }
        if (jobType != "") {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "filter_submit",
                event_category: "filter submit",
                event_action: "job offers",
                event_label: "tipos trabajo : " + jobType.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_value: undefined,
                category: "job offers",
                option: "tipos trabajo : " + jobType.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
            });
        }

        const searchParams = new URLSearchParams();
        searchParams.append("language", culture);
        searchParams.append("pageIndex", page);
        searchParams.append("includeFeaturedItems", "true");
        searchParams.append("source", jobType);
        searchParams.append("division", encodeURIComponent(division));
        searchParams.append("country", country);
        searchParams.append("area", area);

        //var url = `/api/CmsController/GetListOffersPaginated?language=${culture}&pageIndex=${page}&includeFeaturedItems=true&source=${jobType}&division=${division}&country=${country}&area=${area}`;
        var url = `/api/CmsController/GetListOffersPaginated?${searchParams.toString()}`;

        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {

                    var offerList = JSON.parse(xhrPages.responseText);

                    const sidePages = 7;

                    var nextList = [];
                    var befList = [];
                    nextList = offerList.pagePagination.pagesList.slice(page - 1, page + sidePages);
                    befList = offerList.pagePagination.pagesList.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);

                    if (actualPagesList.length === 0 && page !== 1) {

                        setActualPagesList(nextList);
                    }

                    else {

                        if (page === 1) {
                            setActualPagesList(nextList);
                        }
                        else if (page === offerList.pagePagination.pagesList[offerList.pagePagination.pagesList.length - 1].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page === actualPagesList[actualPagesList.length - 1].num) {
                            setActualPagesList(nextList);
                        }
                        else if (page === offerList.pagePagination.pagesList[offerList.pagePagination.pagesList.length - 1].num || page === actualPagesList[0].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page < actualPagesList[0].num) {
                            befList = offerList.pagePagination.pagesList.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
                            setActualPagesList(befList);
                        }
                        else if (page > offerList.pagePagination.pagesList[offerList.pagePagination.pagesList.length - 1].num) {
                            nextList = offerList.pagePagination.pagesList.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
                            setActualPagesList(nextList);
                        }
                    }
                    setlistOffersState(offerList.offerList);
                    setPagesListState(offerList.pagePagination.pagesList);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);

    };

    const changePage = (page) => {

        document.getElementById("header").scrollIntoView();

        setActualPageState(page);
        getOffers(page);
    };

    const nextPage = () => {

        document.getElementById("header").scrollIntoView();

        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);
            getOffers(actualPageState + 1);
        }
        if (window !== null) {
            var url = window.location.href;
            var urlPageNumber = url.match('#(.+)');
            if (urlPageNumber !== null && urlPageNumber.length > 0) {

                var urlNumber = parseInt(urlPageNumber[1]) !== pagesListState.length ? urlPageNumber[1] : parseInt(urlPageNumber[1] - 1);
                var newurl = url.replace(urlNumber, (actualPageState + 1).toString());
                window.location.href = newurl;
            }
            else {
                window.location.href = url + '#' + (actualPageState + 1);
            }
        }
    };

    const prevPage = () => {

        document.getElementById("header").scrollIntoView();

        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);
            getOffers(actualPageState - 1);
        }
        if (window !== null) {

            var url = window.location.href;
            var urlPageNumber = url.match('#(.+)');
            if (urlPageNumber !== null && urlPageNumber.length > 0) {

                var urlNumber = urlPageNumber[0] !== '#1' ? urlPageNumber[0] : '#2';
                var newurl = url.replace(urlNumber, '#' + (actualPageState - 1).toString());

                window.location.href = newurl;
            }
            else {
                window.location.href = url + '#' + (actualPageState - 1);
            }
        }
    };

    const DoSwitch = (valueSelected, obj, filter) => {

        switch (valueSelected) {
            case 'countries': obj.countries = filter;
                break;
            case 'divisions': obj.divisions = filter;
                break;
            case 'areas': obj.areas = filter;
                break;
            default:
                obj.jobType = filter;
                break;

        };
    };

    const GetSetCookie = (valueSelected, filter, cookieValues, valuesFilters) => {

        if (cookieValues != null) {

            valuesFilters = JSON.parse(cookieValues);
            DoSwitch(valueSelected, valuesFilters, filter);
            setCookie('filtersValues', valuesFilters, 10);
        }
        else {
            DoSwitch(valueSelected, valuesFilters, filter);
            setCookie('filtersValues', valuesFilters, 10);
        }
    };

    const onChangeFilter = (valueSelected, event) => {

        country = countriesSelectedState ? countriesSelectedState : '';
        area = areasSelectedState ? areasSelectedState : '';
        division = divisionsSelectedState ? divisionsSelectedState : '';
        jobtype = jobTypeSelectedState ? jobTypeSelectedState : "Profesional";

        var cookieValues = getCookie('filtersValues');

        if (cookieValues == null) {

            var valuesFilters = {
                countries: '',
                divisions: '',
                areas: '',
                jobType: ''
            };
        }

        if (valueSelected === 'countries') {

            setCountriesSelectedState(event.target.value);
            country = event.target.value;
            GetSetCookie(valueSelected, country, cookieValues, valuesFilters);

        }
        if (valueSelected === 'areas') {

            setAreasSelectedState(event.target.value);
            area = event.target.value;
            GetSetCookie(valueSelected, area, cookieValues, valuesFilters);
        }
        if (valueSelected === 'divisions') {

            setDivisionsSelectedState(event.target.value);
            division = event.target.value;
            GetSetCookie(valueSelected, division, cookieValues, valuesFilters);
        }
        if (valueSelected === 'jobType') {

            setJobTypeSelectedState(event.target.value);
            jobtype = event.target.value;
            GetSetCookie(valueSelected, jobtype, cookieValues, valuesFilters);
        }

        if (country !== '' && country !== undefined || area !== '' && area !== undefined
            || division !== '' && division !== undefined || jobtype !== '' && jobtype !== undefined) {

            try {
                Promise.all([
                    fetch(`/api/CmsController/GetFilter?source=${jobtype}&language=${culture}&nameDivision=${division}&nameCountry=${country}&nameArea=${area}`, {
                        method: 'GET'
                    }).then(data => data.json())
                ]).then((data) => {
                    setAreasState(data[0].areas);
                    setDivisionsState(data[0].divisions);
                    setCountriesState(data[0].countries);
                });
            }
            catch (err) {
                console.log(err);
            }
        }
    };


    const changeFilterLastOffers = (divisionName, countryName, areaName, jobType) => {

        if (areasState.length === 0 && divisionsState.length === 0 && countriesState.length === 0) {

            divisionName = divisionName === 'Energia' ? 'Energía' : divisionName;
            var url = `/api/CmsController/GetFilter?source=Profesional&language=${culture}&nameDivision=${divisionName}&nameCountry=${countryName}&nameArea=`;
            const xhrPages = new XMLHttpRequest();
            xhrPages.open('get', url, true);
            xhrPages.onload = function (e) {
                if (xhrPages.readyState === 4) {
                    if (xhrPages.status === 200) {

                        var combosList = JSON.parse(xhrPages.responseText);

                        setDivisionsSelectedState(divisionName);
                        setAreasState(combosList.areas);
                        setDivisionsState(combosList.divisions);
                        setCountriesState(combosList.countries);
                        setCountriesSelectedState(countryName);

                        setAreasSelectedState(areaName);
                        setJobTypeSelectedState(jobType);
                    }
                    else {
                        console.error(xhrPages.statusText);
                    }
                }
            }.bind(this);
            xhrPages.send(null);
        }
    };

    useEffect(() => {
        if (window !== null) {
            var division = findGetParameter("solution");
            var country = findGetParameter("country");
            var area = findGetParameter("area");
            var type = findGetParameter("type");

            var cookieValues = getCookie('filtersValues');

            if (cookieValues != null || cookieValues != undefined) {
                var cookieToObj = JSON.parse(cookieValues);

                division = cookieToObj.divisions;
                country = cookieToObj.countries;
                area = cookieToObj.areas;
                jobtype = cookieToObj.jobtype;
            };

            var urlPageNumber = window.location.href.match('#(.+)');
            var pageNumber = urlPageNumber ? urlPageNumber[1] : '1';

            setActualPageState(parseInt(pageNumber));

            changeFilterLastOffers(division, country, area, type);
            getOffers(parseInt(pageNumber), division, country, area, type);
        }

    }, [setPagesListState]);

    return (

        <div className=''>
            <BuscadorFiltros type='empleo' areasCombo={areasState} divisionsCombo={divisionsState} countriesCombo={countriesState} relationsFilter={onChangeFilter} countrySelected={countriesSelectedState} divisionSelected={divisionsSelectedState} areaSelected={areasSelectedState} jobTypeSelected={jobTypeSelectedState} getListOffers={getOffers}
                labelAllareas={labelAllareas} labelAllcountries={labelAllcountries} labelAllsolutions={labelAllsolutions} labelArea={labelArea}
                labelBecas={labelBecas} labelCountry={labelCountry} labelEligeOpcion={labelEligeOpcion} labelGraduates={labelGraduates} labelProfesional={labelProfesional}
                labelSolution={labelSolution} labelVacancieType={labelVacancieType} labelOcultarFiltros={labelOcultarFiltros} labelMostrarFiltros={labelMostrarFiltros} />
            <TrabajaConNosotrosSimple frames={listOffersState} linkOfferDetail={linkOfferDetail} culture={culture} labelDe={labelDe} />
            {pagesListState.length > 1 &&
                <Paginator pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} actualPagesList={actualPagesList} />
            }
        </div>

    );
};

export default WorkDay;
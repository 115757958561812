// button.component.js
import React, { useState } from 'react';
import './styles.scss';
import { CheckboxGroup, TagTitle } from 'React/components';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconShow } from 'Icons/filteropen-16.svg';
import { ReactComponent as IconHide } from 'Icons/filterclose-16.svg';
import { useFade } from '../../custom_hooks';
import { globalTranslations } from 'window-or-global';

const BuscadorFiltrosProyectos = ({ type, title, searchFilter,
    hideCombo, solutionsCombo, areasCombo, countriesCombo,
    getProjectsList, culture, whatTypeProjectLabel, setActiveSolution, activeSolution, setActiveArea, activeArea, searchProjects, activeCountry, setActiveCountry, hideFilters }) => {

    const [filterState, setFilterState] = useState(false);
    const [filterAreaState, setFilterAreaState] = useState('');

    let showFiltersLabel = typeof globalTranslations !== 'undefined' ? globalTranslations.showFilters : '';
    let hideFiltersLabel = typeof globalTranslations !== 'undefined' ? globalTranslations.hideFilters : '';

    const _btnIcon = () => (filterState === true ? <IconShow /> : <IconHide />);
    const _btnLabel = () => (filterState === true ? showFiltersLabel : hideFiltersLabel);

    let moduleRef = React.createRef();

    useFade(moduleRef);

    if (type === 'proyectos') {
        return (
            <div
                ref={moduleRef}
                className={`grid module module-buscadorfiltros--proyectos module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >

                <div className="grid-container">
                    <div className="grid-container__title-filter-btn">
                        <h2 className="title--s mb-2 slow--y">{whatTypeProjectLabel} </h2>
                        {!hideFilters &&
                            <div className="grid-container">
                                <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                                    <span className="taglabel">
                                    <_btnIcon /> <_btnLabel />
                                    </span>
                                </button>
                            </div>
                        }
                    </div>
                    <form className="filters-container">
                        <div className="selects-container">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" value={activeSolution} onChange={setActiveSolution}>
                                        <option value="">
                                            {typeof globalTranslations !== 'undefined' ? globalTranslations.solution : ''}
                                        </option>
                                        {solutionsCombo.map((item, idx) => {
                                            return (
                                                <option key={`solutions-combo-${idx}`} value={item.value}>{item.name}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{typeof globalTranslations !== 'undefined' ? globalTranslations.selectSolution : ''}</label>
                                </div>
                                {activeSolution &&
                                    <div className="select">
                                        <select name="slct" id="slct" value={activeArea} onChange={setActiveArea}>
                                        <option value="">
                                            {typeof globalTranslations !== 'undefined' ? globalTranslations.activityArea : ''}
                                            </option>
                                            {areasCombo.map((item, idx) => {
                                                return (
                                                    <option key={`areas-combo-${idx}`} value={item.value}>{item.name}</option>
                                                );
                                            })}
                                    </select>
                                    <label className="taglabel">{typeof globalTranslations !== 'undefined' ? globalTranslations.selectActivityArea : ''}</label>
                                    </div>
                                }
                                <div className="select">
                                    <select name="slct" id="slct" value={activeCountry} onChange={setActiveCountry}>
                                        <option value="">
                                            {typeof globalTranslations !== 'undefined' ? globalTranslations.country : ''}
                                        </option>
                                        {countriesCombo.map((item, idx) => {
                                            return (
                                                <option key={`countries-combo-${idx}`} value={item}>{item}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{typeof globalTranslations !== 'undefined' ? globalTranslations.selectCountry : ''}</label>
                                </div>
                            </div>
                        </div>
                        <div className="checkbox-filters">
                            <div className="checkbox-filter">
                                <CheckboxGroup label={typeof globalTranslations !== 'undefined' ? globalTranslations.projectsWith : ''} check1={typeof globalTranslations !== 'undefined' ? globalTranslations.innovation : ''} id1="innovacion"
                                    check2={typeof globalTranslations !== 'undefined' ? globalTranslations.sustainability : ''} id2="sostenibilidad" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={activeSolution === 'Energia' ? {} : { display: 'none' }}>
                                <CheckboxGroup label={typeof globalTranslations !== 'undefined' ? globalTranslations.state : ''} check1={typeof globalTranslations !== 'undefined' ? globalTranslations.installed : ''} id1="instalada"
                                    check2={typeof globalTranslations !== 'undefined' ? globalTranslations.inConstruction : ''} id2="en-construccion" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={(activeSolution === 'Transporte' || activeSolution === 'Agua') ? {} : { display: 'none' }}>
                                <CheckboxGroup label={typeof globalTranslations !== 'undefined' ? globalTranslations.state : ''} check1={typeof globalTranslations !== 'undefined' ? globalTranslations.executed : ''} id1="ejecutado"
                                    check2={typeof globalTranslations !== 'undefined' ? globalTranslations.inConstruction : ''} id2="en-construccion2" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={(activeSolution === 'Transporte' || activeSolution === 'Agua') ? {} : { display: 'none' }}>
                            </div>
                            <div className="checkbox-filter" style={activeSolution === 'Energia' ? {} : { display: 'none' }}>
                                <CheckboxGroup label={typeof globalTranslations !== 'undefined' ? globalTranslations.ownership : ''} check1={typeof globalTranslations !== 'undefined' ? globalTranslations.owned : ''} id1="en-propiedad"
                                    check2={typeof globalTranslations !== 'undefined' ? globalTranslations.client : ''} id2="cliente" event={searchFilter} />
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn" onClick={() => searchProjects()}>
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }

    if (type === 'proyectos-simple') {
        return (
            <div
                ref={moduleRef}
                className={`slow grid module module-buscadorfiltros--proyectos module-buscadorfiltros--proyectos-simple module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >
                <div className="grid-container">
                    <form className="filters-container ">
                        <div className="selects-container select-proyectos-simple">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" value={activeSolution} onChange={setActiveSolution}>
                                        <option value="">
                                            {typeof globalTranslations !== 'undefined' ? globalTranslations.solution : ''}
                                        </option>
                                        {solutionsCombo.map((item, idx) => {
                                            return (
                                                <option key={`solutions-combo-${idx}`} value={item.value}>{item.name}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{typeof globalTranslations !== 'undefined' ? globalTranslations.solution : ''}</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" value={activeCountry} onChange={setActiveCountry}>
                                        <option value="">
                                            {typeof globalTranslations !== 'undefined' ? globalTranslations.country : ''}
                                        </option>
                                        {countriesCombo.map((item, idx) => {
                                            return (
                                                <option key={`countries-combo-${idx}`} value={item}>{item}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{typeof globalTranslations !== 'undefined' ? globalTranslations.country : ''}</label>
                                </div>
                                <div className="checkbox-filters">
                                    <div className="checkbox-filter">
                                        <CheckboxGroup label={typeof globalTranslations !== 'undefined' ? globalTranslations.projectType : ''} check1={culture === 'es' ? "Innovación" : "Innovation"} check2={culture === 'es' ? "Sostenibilidad" : "Sustainability"} event={searchFilter} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn" onClick={() => searchProjects()}>
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }
};

export default BuscadorFiltrosProyectos;

import React, { useState } from 'react';
import { ReactComponent as IconDownload } from 'Icons/download.svg';
import './styles.scss';
import { Link, Lightbox, Wysiwyg, TagTitle, EnlaceAgrupador } from 'React/components';
import { rndKeyGen } from 'React/helpers';
import { useSlowFade } from 'React/custom_hooks';

const DescargasItem = ({ /*type, title, link, lightboxContent, downloads, groupLink, position,*/ list }) => {
    const [visibleLightboxState, setVisibleLightboxState] = useState(false);
    const [lightboxContentState, setLightboxContentState] = useState('test');

    let moduleRef = React.createRef();
    //useSlowFade(moduleRef);

    function isOdd(num) { return num % 2; }

    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContentState(content);
            setVisibleLightboxState(true);
        }
    };

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    return (
        <div ref={moduleRef} className="module descargas descargas--sigle grid">
            {list.map((item, idx) => {
                const { type, title, link, lightboxContent, downloads, groupLink } = item;
                return (
                    <>
                        {downloads && !groupLink &&
                            <div className={isOdd(idx + 1) ? "descargas__col-left box-custom-margin" : "descargas__col-right box-custom-margin"}>
                                {title && <h2 className="title--m slow--y">{title}</h2>}
                                <ul className="list list-downloads">
                                    {link &&
                                        <li className="slow--y">
                                            {lightboxContent &&
                                                <span onClick={() => handleLightboxClick(lightboxContent)}><Link label={link.caption} type='lightbox' /></span>
                                            }
                                            {!lightboxContent &&
                                                <Link type={link.type} label={link.caption} path={link.link} target={link.target} />
                                            }
                                        </li>
                                    }
                                    {downloads.map((item, idx) => {
                                        if (item.umbracoFile) {
                                            return <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y"><a className="icon--red link module--cta" target='_blank' href={item.umbracoFile ? item.umbracoFile : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                                <p>{item.name ? item.name : ''}</p><p className="link--download-type">{item.umbracoExtension.toUpperCase()} {bytesToSize(item.umbracoBytes)}</p></a>
                                            </li>
                                        }
                                        else {
                                            return <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y"><a className="icon--red link module--cta" target='_blank' href={item.file ? item.file : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                                <p>{item.title ? item.title : item.recordNumber}</p><p className="link--download-type">PDF {/*{bytesToSize(item.umbracoBytes)*/}</p></a>
                                            </li>
                                        }
                                    })}
                                </ul>
                            </div>
                        }
                        {downloads && groupLink &&
                            <div className="descargas__col-left">
                                {title && <h2 className="title--m slow--y">{title}</h2>}
                                <ul className="list list-downloads">
                                    {link &&
                                        <li className="slow--y">
                                            {lightboxContent &&
                                                <span onClick={() => handleLightboxClick(lightboxContent)}><Link label={link.caption} type='lightbox' /></span>
                                            }
                                            {!lightboxContent &&
                                                <Link type={link.type} label={link.caption} path={link.link} target={link.target} />
                                            }
                                        </li>
                                    }
                                    <div><EnlaceAgrupador links={downloads} box_label={groupLink} /></div>
                                </ul>
                            </div>
                        }
                    </>
                );
            })}
            <Lightbox
                key={rndKeyGen()}
                isVisible={visibleLightboxState}
                closeMethod={() => setVisibleLightboxState(null)}
            >
                <Wysiwyg html={lightboxContentState} />
            </Lightbox>
        </div>
    );
};

export default DescargasItem;

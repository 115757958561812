import React, { useState } from 'react'
import { TagTitle, Link } from 'React/components';
import { IconsMap } from '../Icons/Icons'
import { useSlowFade } from 'React/custom_hooks';

import { removeAccents } from '../../helpers';
import useDeviceType from '../../custom_hooks/useDeviceType';
import useTranslate from '../../custom_hooks/useTranslate';



const SolutionAreas = (props) => {

    let moduleRef = React.createRef();
    useSlowFade(moduleRef);

    const { tagTitle, cta, headers, _contentPath, titleText } = props;

    const subModules = props.subModules;
    const device = useDeviceType();

    const [expandedSolutionIndices, setExpandedSolutionIndices] = useState({});

    const solutionLink = _contentPath.startsWith('/es/')
        ? '/es/soluciones/'
        : '/solutions/';

    const data = headers.map((header, idx) => ({
        header,
        subModules: subModules.map((row) => row.subModules[idx]),
        linkToSolution: `${solutionLink}${removeAccents(header)}/`,
    }));

    const toggleVisibility = (idx) => {
        setExpandedSolutionIndices((prevIndices) => ({
            ...prevIndices,
            [idx]: !prevIndices[idx],
        }));
    };

    return (
        <div className="module table-solution-areas">
            <div className="table-solution-title grid">
                {tagTitle && <TagTitle title={tagTitle} />}
            </div>

            <div className="solution-areas">
                {data.map((item, idx) => {
                    const {
                        header,
                        linkToSolution,
                        subModules,
                    } = item;

                    const isMobileOrTablet = device === 'mobile' || device === 'tablet';
                    const isExpanded = isMobileOrTablet && expandedSolutionIndices[idx];

                    const toggleVisibilityHandler = () => {
                        toggleVisibility(idx);
                    };

                    return (
                        <div key={idx} className="container">
                            <div className="title-container">
                                <span className="solution-areas-icon">
                                    {IconsMap[useTranslate(header.replace(/\s/g, ''))]}
                                </span>
                                <a href={linkToSolution}>
                                    <h1 className="link header-text">{header} </h1>
                                </a>

                                {isMobileOrTablet && (
                                    <button className="menu-button" onClick={toggleVisibilityHandler}>
                                        {isExpanded ? IconsMap['IconMinus'] : IconsMap['IconPlus']}
                                    </button>
                                )}
                            </div>

                            <div className={`area-container ${isMobileOrTablet && !isExpanded ? 'collapsed' : ''}`}>
                                <ul className="link area-list">
                                    {subModules.map((areaName, areaIdx) => {
                                        const { _documentType, text, className } = areaName;
                                        const textOneLine = text.replace('<br />', ' ');

                                        return (
                                            <React.Fragment key={areaIdx}>
                                                {text && (
                                                    <div className="--left" dangerouslySetInnerHTML={{ __html: textOneLine }}></div>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </ul>

                                {isMobileOrTablet && (
                                    <div className="link-solution">
                                        <Link
                                            path={linkToSolution}
                                            label={linkToSolution.startsWith('/es/') ? `Ir a ${header}` : `Go to ${header}`}
                                            type={cta.type}  // Asegúrate de tener cta definido o ajusta según sea necesario
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}

            </div>
            {cta && (
                <div className="table-solution-title grid">
                    <Link label={cta.caption} path={cta.link} type={cta.type} />
                </div>
            )}
        </div>
    );
};

export default SolutionAreas;


import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, Distribuidor2Col, PaginatorSimple, Breadcrumbs } from 'React/components';
import useAnalytics from '../../custom_hooks/useAnalytics';

const BingResults = ({ results, itemsByPage, breadcrumb, search }) => {


    const [resultsListState, setResultsListState] = useState([]);
    const [resultsPaginatedListState, setResultsPaginatedListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);

    const itemPerPage = parseInt(itemsByPage) !== 0 ? parseInt(itemsByPage) : 4;

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const changePage = (page) => {
        document.getElementById("scrollTo").scrollIntoView();
        setActualPageState(page);

        var ownTechList = paginate(resultsPaginatedListState, itemPerPage, page);
        setResultsListState(ownTechList);
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            document.getElementById("scrollTo").scrollIntoView();
            setActualPageState(actualPageState + 1);
            var ownTechList = paginate(resultsPaginatedListState, itemPerPage, actualPageState + 1);
            setResultsListState(ownTechList);
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            document.getElementById("scrollTo").scrollIntoView();
            setActualPageState(actualPageState - 1);
            var ownTechList = paginate(resultsPaginatedListState, itemPerPage, actualPageState - 1);
            setResultsListState(ownTechList);
        }
    };

    useEffect(() => {
        breadcrumb["search"] = search;
        setResultsPaginatedListState(results);
        var pagesList = [];
        var listSize = Math.ceil(results.length / itemPerPage);

        for (var i = 0; i !== listSize; i++) {
            var page = { num: i + 1 };
            pagesList.push(page);
        }
        var resultsList = paginate(results, itemPerPage, actualPageState);
        setResultsListState(resultsList);
        setPagesListState(pagesList);
    }, [setPagesListState]);

    useEffect(() => {
        useAnalytics('search', search, results)
    }, [search, results])

    if (resultsListState.length > 0) {
        return (
            <div className='module pagination-list-container'>
                <span className="custom-anchor-small" id="scrollTo" />
                <Distribuidor2Col subModules={resultsListState} />
                {pagesListState.length > 1 &&
                    <PaginatorSimple pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} />
                }
            </div>
        );
    }
    else {
        return (<div className='module pagination-list-container'></div>);
    }

}

export default BingResults;
import React, { useState, useEffect, useRef } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { Link } from 'React/components';
import { TagTitle } from 'React/components';
import './styles.scss';

const DistribuidorDestacadoAreas = ({
    moduleTitle,
    title,
    titleBlock,
    cipher1,
    supportTextCipher1,
    cipher2,
    supportTextCipher2,
    text,
    href,
    image,
    imageReplace,
    tagTitle,
    anchor
}) => {

    const onHover = (event) => {
        event.currentTarget.parentNode.parentNode.classList.add('is-hovered');
    };

    const notHover = (event) => {
        event.currentTarget.parentNode.parentNode.classList.remove('is-hovered');
    };

    let moduleRef = React.createRef();
    useSlowFade(moduleRef);

    if (typeof window !== 'undefined') {
        const imageUrl = useWindowWidth() >= 767 ? image : image;

        return (
            <section
                ref={moduleRef}
                className={`module grid module-distribuidor-destacado  module-distribuidor-destacado--areas grid-flow--left slow--y`}
            >
                {moduleTitle && <h2 className="title--tag grid-container">{moduleTitle}</h2>}
                <div className={`grid-container--left slow--y `} style={{ backgroundImage: `url(${imageUrl ? imageUrl.url : ''})` }}>
                    {cipher1 &&
                        <div className="cifras-container slow--y">
                            {cipher1 && (
                                <div className="slow--y">
                                    <span className="cifras--m slow--y">{cipher1}</span>
                                    <p className="taglabel slow--y">{supportTextCipher1}</p>
                                </div>
                            )}
                            {cipher2 && (
                                <div className="slow--y">
                                    <span className="cifras--m slow--y">{cipher2}</span>
                                    <p className="taglabel slow--y">{supportTextCipher2}</p>
                                </div>
                            )}
                        </div>
                    }
                </div>
                <span className="custom-anchor-small" id={anchor} />
                <div className={`grid-container--right slow--y`}>
                    {tagTitle && <TagTitle className="slow--y" title={tagTitle} />}

                    {titleBlock && <h2 className="title--xs mb-2 slow--y">{titleBlock}</h2>}

                    {title && (
                        <h2 className="title--l slow--y" onMouseOut={notHover} onMouseOver={onHover}>
                            {href ? (<a href={href ? href.link : ''} target={href ? href.target : ''}>{title}</a>) : title}
                        </h2>
                    )}

                    {text && (
                        <div className="mt-2 slow--y">
                            <div className="body--m slow--y" dangerouslySetInnerHTML={{ __html: text }}></div>
                        </div>
                    )}
                    {href && (
                        <div className="mt-3 slow--y">
                            <Link type="arrow" path={href.link} target={href.target} />
                        </div>
                    )}
                </div>
            </section>
        );
    }
    else {
        return (
            <section ref={moduleRef} className={`module grid module-distribuidor-destacado  module-distribuidor-destacado--areas grid-flow--left slow--y`}>
                {moduleTitle && <h2 className="title--tag grid-container">{moduleTitle}</h2>}
            </section>
        );
    }
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default DistribuidorDestacadoAreas;
